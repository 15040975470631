import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import BookingStore from "booking/store/BookingStore";
import { SkinAssessmentBookingData } from "common";
import { Formik, ErrorMessage, Field } from "formik";
import { Input } from "morse-react";
import * as yup from "yup";
import { InputYesNo } from "core/components/generic/InputYesNo";
import CIDLabel from "core/components/generic/CIDLabel";
import {
    ChangingMoleOrLesionError,
    Over18YearsError,
    NoMobilityIssuesError,
} from "./validation/SkinAssessmentValidation";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";
import { useOk } from "morse-react-dialogs";

const SkinAssessmentInformation = observer(() => {
    const store = BookingStore.get();
    const bookingData = store.bookingData as SkinAssessmentBookingData;

    bookingData.nhsNumber = store.bookingData.contactDetails.nhsNumber ?? "";

    const [showOver18YearsError, setShowOver18YearsError] = useState<boolean>(false);
    const [showChangingMoleOrLesionError, setShowChangingMoleOrLesionError] = useState<boolean>(
        false
    );
    const [showNoMobilityIssuesError, setShowNoMobilityIssuesError] = useState<boolean>(false);

    const onSubmit = async (values: Partial<SkinAssessmentBookingData>) => {
        await SubmitBookingUseCase.create().execute({ ...bookingData, ...values });
    };

    const validateOver18Years = (value: string) => {
        setShowOver18YearsError(value === "false" ? true : false);
    };

    const validateChangingMoleOrLesion = (value: string) => {
        setShowChangingMoleOrLesionError(value === "false" ? true : false);
    };

    const validateNoMobilityIssues = (value: string) => {
        setShowNoMobilityIssuesError(value === "true" ? true : false);
    };

    const highRiskInfo = useOk({
        title: "High Risk",
        message: (
            <span>
                <p className="u-marg-bottom">You are considered High Risk if you have:</p>
                <ul className="u-marg-bottom u-bullet-list">
                    <li>family history of melanoma</li>
                    <li>sunbed use</li>
                    <li>peeling, blistering sunburn particularly in childhood</li>
                    <li>lived abroad or frequent travel abroad</li>
                    <li>are an outdoor worker</li>
                </ul>
            </span>
        ),
    });

    const changingMoleOrLesionInfo = useOk({
        title: "Changing/New Lesions/Moles",
        message: (
            <span>
                <p className="u-marg-bottom">
                    A new or changing/non-healing mole or lesion includes:
                </p>
                <ul className="u-marg-bottom u-bullet-list">
                    <li>increasing in size</li>
                    <li>changing in shape or colour</li>
                    <li>itch</li>
                    <li>crusting</li>
                    <li>stinging</li>
                    <li>bleeding</li>
                </ul>
            </span>
        ),
    });

    return (
        <>
            {showOver18YearsError && (
                <Over18YearsError
                    open={showOver18YearsError}
                    onClose={() => setShowOver18YearsError(false)}
                />
            )}
            {showChangingMoleOrLesionError && (
                <ChangingMoleOrLesionError
                    open={showChangingMoleOrLesionError}
                    onClose={() => setShowChangingMoleOrLesionError(false)}
                />
            )}
            {showNoMobilityIssuesError && (
                <NoMobilityIssuesError
                    open={showNoMobilityIssuesError}
                    onClose={() => setShowNoMobilityIssuesError(false)}
                />
            )}
            <Formik
                initialValues={{
                    over18Years: bookingData.over18Years,
                    highRisk: bookingData.highRisk,
                    changingMoleOrLesion: bookingData.changingMoleOrLesion,
                    confirmEmailAddress: bookingData.confirmEmailAddress,
                    confirmVirtualAppointment: bookingData.confirmVirtualAppointment,
                    noMobilityIssues: bookingData.noMobilityIssues,
                    consentToShareInfo: bookingData.consentToShareInfo,
                    nhsNumber: bookingData.nhsNumber,
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    over18Years: yup
                        .bool()
                        .oneOf([true], "Not Eligible for this service.")
                        .required("Please choose Yes or No"),
                    highRisk: yup.bool().required("Please choose Yes or No"),
                    changingMoleOrLesion: yup
                        .bool()
                        .oneOf([true], "Not Eligible for this service.")
                        .required("Please choose Yes or No"),
                    confirmEmailAddress: yup.bool().required("Please choose Yes or No"),
                    confirmVirtualAppointment: yup.bool().required("Please choose Yes or No"),
                    noMobilityIssues: yup
                        .bool()
                        .oneOf([true], "Not Eligible for this service.")
                        .required("Please choose Yes or No"),
                    consentToShareInfo: yup
                        .bool()
                        .oneOf([true], "You must agree to proceed")
                        .required("Please choose Yes or No"),
                    nhsNumber: yup
                        .string()
                        .nhsNumber("Not a valid Health and Care Number")
                        .required("Please enter your Health and Care Number"),
                })}
                validateOnChange={true}
            >
                {({ submitForm, values, handleChange, isSubmitting, errors, setFieldValue }) => {
                    return (
                        <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
                            <div className="l-app__item +middle">
                                <div className="u-height-100pc u-pad">
                                    <div className="u-flex u-align-items-center u-justify-content-center">
                                        <div className="o-wrap u-width-login">
                                            <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                                                <div className="u-pad-large">
                                                    <p className="u-font-bold u-font-xxl">
                                                        Skin Cancer Early Detection Questions
                                                    </p>
                                                    <fieldset className="u-pad-none">
                                                        <div
                                                            className={`c-field ${
                                                                errors.nhsNumber ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                required
                                                                className="u-marg-none"
                                                            >
                                                                Health and Care Number
                                                            </CIDLabel>
                                                            <Input
                                                                className="+text +fill"
                                                                name={"nhsNumber"}
                                                                value={values.nhsNumber}
                                                                onChange={handleChange("nhsNumber")}
                                                            />
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"nhsNumber"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.over18Years ? "+error" : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="over18Years"
                                                            component={InputYesNo}
                                                            label="Are you over 18yrs?"
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "over18Years",
                                                                    e.target.value === "true"
                                                                );
                                                                validateOver18Years(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field ${
                                                            errors.highRisk ? "+error" : ""
                                                        }`}
                                                    >
                                                        <CIDLabel required>
                                                            Are you high risk?
                                                            <button
                                                                title="Click here for more information"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    verticalAlign: "sub",
                                                                }}
                                                                type="button"
                                                                className="c-button +text"
                                                                onClick={() => highRiskInfo.show()}
                                                            >
                                                                <svg className="c-icon u-font-xl">
                                                                    <use href="/symbol-defs.svg#icon-question-circle" />
                                                                </svg>
                                                            </button>
                                                        </CIDLabel>
                                                        <Field
                                                            name="highRisk"
                                                            component={InputYesNo}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "highRisk",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.changingMoleOrLesion
                                                                ? "+error"
                                                                : ""
                                                        }`}
                                                    >
                                                        <CIDLabel required>
                                                            Do you have a changing/non-healing
                                                            mole/lesion?
                                                            <button
                                                                title="Click here for more information"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    verticalAlign: "sub",
                                                                }}
                                                                type="button"
                                                                className="c-button +text"
                                                                onClick={() =>
                                                                    changingMoleOrLesionInfo.show()
                                                                }
                                                            >
                                                                <svg className="c-icon u-font-xl">
                                                                    <use href="/symbol-defs.svg#icon-question-circle" />
                                                                </svg>
                                                            </button>
                                                        </CIDLabel>
                                                        <Field
                                                            name="changingMoleOrLesion"
                                                            component={InputYesNo}
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "changingMoleOrLesion",
                                                                    e.target.value === "true"
                                                                );
                                                                validateChangingMoleOrLesion(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.confirmEmailAddress
                                                                ? "+error"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="confirmEmailAddress"
                                                            component={InputYesNo}
                                                            label="Your email address will be used to send you correspondence about your appointment outcomes - confirm you understand"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "confirmEmailAddress",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.confirmVirtualAppointment
                                                                ? "+error"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="confirmVirtualAppointment"
                                                            component={InputYesNo}
                                                            label="The first stage of the appointment is virtual and will be carried out using MS Teams – confirm you understand"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "confirmVirtualAppointment",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.noMobilityIssues ? "+error" : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="noMobilityIssues"
                                                            component={InputYesNo}
                                                            label="I have mobility issues that would prevent me removing clothing/redressing for the clinical assessment"
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "noMobilityIssues",
                                                                    e.target.value === "false"
                                                                );
                                                                validateNoMobilityIssues(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.consentToShareInfo
                                                                ? "+error"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="consentToShareInfo"
                                                            component={InputYesNo}
                                                            label="Consent - if you require a referral to a skin clinic, your information will be shared with the relevant health providers"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "consentToShareInfo",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l-app__item +end">
                                <div className="u-pad u-fill-white u-border-top">
                                    <div className="u-flex c-footer">
                                        <button
                                            type="button"
                                            className="c-button +quiet u-marg-right u-marg-bottom-small"
                                            onClick={() => {
                                                store.setEnteringPersonalDetails(true);
                                            }}
                                        >
                                            <svg className="c-icon u-font-xl u-marg-right-small">
                                                <use href="/symbol-defs.svg#icon-arrow-left" />
                                            </svg>
                                            Back
                                        </button>

                                        <button
                                            type="submit"
                                            className="c-button u-fill-1 +primary u-marg-bottom-small"
                                            onClick={submitForm}
                                            disabled={isSubmitting}
                                        >
                                            Book Appointment
                                            <svg className="c-icon u-font-xl u-marg-left-small">
                                                <use href="/symbol-defs.svg#icon-check" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
});

export default SkinAssessmentInformation;
