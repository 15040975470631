import BookingStore from "../store/BookingStore";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Input, Label } from "morse-react";
import InitiateLoginWithEmailUseCase from "../use-cases/authentication/InitiateLoginWithEmailUseCase";
import LoginWithCodeUseCase from "booking/use-cases/authentication/LoginWithCodeUseCase";
import { AuthenticationType } from "common";
import InitiateLoginWithPhoneUseCase from "booking/use-cases/authentication/InitiateLoginWithPhoneUseCase";
import FetchProfileUseCase from "../use-cases/FetchProfileUseCase";

type VerifyCodeProps = {
    onHomeScreen?: boolean;
};

const VerifyCode = observer((props: VerifyCodeProps) => {
    const { onHomeScreen } = props;
    const [code, setCode] = useState<string>("");

    const store = BookingStore.get();

    const {
        authenticatingBy,
        loginCredential = "",
        verifyErrorMessage,
        loginErrorMessage,
        initiateLoginDisabled,
    } = store;

    const verify = async () => {
        await LoginWithCodeUseCase.create().execute(code, onHomeScreen);
        const store = BookingStore.get();

        if (!store.verifyErrorMessage && onHomeScreen) {
            await FetchProfileUseCase.create().execute();
        }
    };

    const resendCode = async () => {
        switch (authenticatingBy) {
            case AuthenticationType.Email:
                await InitiateLoginWithEmailUseCase.create().execute(loginCredential);
                break;
            case AuthenticationType.Phone:
                await InitiateLoginWithPhoneUseCase.create().execute(loginCredential);
                break;
        }
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} className="u-height-100pc u-pad">
            <div className="u-flex u-align-items-center u-justify-content-center u-height-100pc">
                <div className="o-wrap u-width-login">
                    <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                        <div className="u-pad-large">
                            <p className="u-font-bold u-font-xxl">Confirm your details</p>
                            {authenticatingBy === AuthenticationType.Phone ? (
                                <p className="u-marg-bottom">
                                    We've sent a text to <strong>{loginCredential}</strong>
                                </p>
                            ) : (
                                <p className="u-marg-bottom">
                                    We've sent an email to <strong>{loginCredential}</strong>
                                </p>
                            )}
                            <div className="u-marg-bottom">
                                <Label>Enter code</Label>
                                <Input
                                    autoFocus
                                    fill
                                    className="+text"
                                    name={"code"}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    autoComplete="one-time-code"
                                    id="otc"
                                    aria-label="Enter the verification code you received"
                                />
                            </div>
                            <button
                                type="submit"
                                className="c-button u-fill-y
                                1 u-width-100pc u-marg-bottom-small"
                                onClick={() => verify()}
                                disabled={!code}
                            >
                                Verify
                            </button>
                            {verifyErrorMessage ||
                                (loginErrorMessage && (
                                    <div className="u-border-top u-marg-top">
                                        <div className="c-alert u-marg-left-right u-marg-top u-fill-neg u-bold u-justify-content-start u-align-self-center">
                                            <span role="img" aria-label="warning">
                                                ⚠️
                                            </span>
                                            <span className="u-flex-grow-1">
                                                {verifyErrorMessage || loginErrorMessage}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            <div className="c-divide u-marg-top-bottom">
                                <span>OR</span>
                            </div>
                            <button
                                type="button"
                                className="c-button +quiet u-width-100pc"
                                onClick={() => resendCode()}
                                disabled={initiateLoginDisabled}
                            >
                                Resend code
                            </button>
                            <button
                                type="button"
                                className="c-button +text +neg +link u-width-100pc"
                                onClick={() => {
                                    store.tryAnotherPhoneOrEmail();
                                }}
                            >
                                {`Try another ${
                                    authenticatingBy === AuthenticationType.Phone
                                        ? "number"
                                        : "email address"
                                } or use your ${
                                    authenticatingBy !== AuthenticationType.Phone
                                        ? "number"
                                        : "email address"
                                } `}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});

export default VerifyCode;
