import TimeSlotsStore from "booking/store/TimeSlotStore";
import SelectAppointmentTypeAndEventCodeUseCase from "booking/use-cases/SelectAppointmentTypeAndEventCodeUseCase";
import { ErrorMessage, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Input, Label, Select } from "morse-react";
import { appointmentTypeLabels, appointmentTypes } from "common";

import React, { useState } from "react";
import * as yup from "yup";
import logo from "../../images/logo.png";
import { useOk } from "morse-react-dialogs";
import ProfileStore from "../store/ProfileStore";
import { Link, useHistory } from "react-router-dom";

type LandingInputs = {
    appointmentType: number;
    privateBookingCode?: string;
};

const Landing = observer(() => {
    const profileStore = ProfileStore.get();
    const timeSlotsStore = TimeSlotsStore.get();

    const [showPrivateBookingCode, setShowPrivateBookingCode] = useState(false);
    const [appointmentType, setAppointmentType] = useState(appointmentTypes.breastScreening);
    const history = useHistory();

    const noAppointmentsDialog = useOk({
        title: "No appointments",
        message: (
            <>
                <p>
                    Sorry, we currently have no upcoming appointments available for this service.
                    Please check back regularly for cancellations or new availability.
                </p>
                {(appointmentType === appointmentTypes.complementaryTherapy ||
                    appointmentType === appointmentTypes.counselling) && (
                    <p>
                        To be added to our waiting list{" "}
                        <a href="https://actioncancer.org/referral/">
                            <u>click here</u>
                        </a>
                    </p>
                )}
            </>
        ),
    });

    const onSubmit = async (values: LandingInputs) => {
        const appointmentTypeId = Number(values.appointmentType);

        // Force user to log in before selecting a SIA appointment
        if (!profileStore.loggedIn && appointmentTypeId === appointmentTypes.stepIntoAction) {
            history.push("/account");
        }

        await SelectAppointmentTypeAndEventCodeUseCase.create().execute(
            appointmentTypeId ?? 0,
            values.privateBookingCode ?? ""
        );

        if (timeSlotsStore.datesWithAppointments?.length === 0) {
            noAppointmentsDialog.show();
        }
    };

    return (
        <div className="u-flex u-align-items-center u-justify-content-center u-height-100vh u-pad">
            <div className="o-wrap u-width-login">
                <div className="u-fill-white u-border u-rounded u-shadow-ambient u-pad-large u-marg-bottom">
                    <img
                        src={logo}
                        alt="Logo"
                        style={{ width: "165px" }}
                        className="u-marg-bottom"
                    />
                    <Formik
                        initialValues={{
                            appointmentType: 0,
                            privateBookingCode: undefined,
                        }}
                        onSubmit={onSubmit}
                        validationSchema={yup.object({
                            appointmentType: yup.string().required(),
                            privateBookingCode: yup.string().notRequired(),
                        })}
                    >
                        {({ submitForm, values, handleChange, isSubmitting, setFieldValue }) => {
                            return (
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <fieldset className="u-pad-none u-marg-bottom">
                                        <Label>Select a service</Label>
                                        <Select
                                            initialOptions={Object.entries(
                                                appointmentTypeLabels
                                            ).map(([value, label]) => ({
                                                label,
                                                value,
                                            })).filter((type => type.value !== "109"))}
                                            fill
                                            onChange={(selected?: string): void => {
                                                setAppointmentType(Number(selected) ?? 0);
                                                return setFieldValue("appointmentType", selected);
                                            }}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name={"service"}
                                            className="c-field__feedback"
                                        />
                                    </fieldset>
                                    {!showPrivateBookingCode ? (
                                        //TODO: Design: style buttons as links
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                            className="u-block u-marg-bottom u-color-link"
                                            onClick={() =>
                                                setShowPrivateBookingCode(!showPrivateBookingCode)
                                            }
                                        >
                                            <u>I have a private booking code</u>
                                        </a>
                                    ) : (
                                        <fieldset className="u-pad-none u-marg-bottom">
                                            <Label>Private booking code</Label>
                                            <p className="u-font-small u-translucent u-marg-bottom-small">
                                                To make a screening or health check appointment at a
                                                private location, please enter the code provided by
                                                your booking organiser
                                            </p>
                                            <Input
                                                fill
                                                className="+text +fill"
                                                name={"privateBookingCode"}
                                                value={values.privateBookingCode}
                                                onChange={handleChange("privateBookingCode")}
                                            />
                                            <ErrorMessage
                                                component="span"
                                                name={"privateBookingCode"}
                                                className="c-field__feedback"
                                            />
                                        </fieldset>
                                    )}

                                    <button
                                        type="submit"
                                        className="c-button u-fill-1 u-width-100pc u-marg-bottom"
                                        onClick={submitForm}
                                        disabled={
                                            isSubmitting ||
                                            (values.appointmentType === 0 &&
                                                !values.privateBookingCode)
                                        }
                                    >
                                        View Appointments
                                    </button>

                                    <div>
                                        <Link
                                            to={"/account"}
                                            className="u-block u-align-center u-color-link"
                                        >
                                            {profileStore.loggedIn ? (
                                                <u>View your profile</u>
                                            ) : (
                                                <u>Login to your account OR Create new account</u>
                                            )}
                                        </Link>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
                <ul className="u-marg-top-large u-translucent">
                    <li>
                        <a
                            href="https://actioncancer.org/referral/"
                            className="u-color-text"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Online Referral
                        </a>
                        <span>*</span>
                    </li>
                </ul>
                <p className="u-translucent u-font-small u-marg-top-small">
                    * This link opens in a new window
                </p>
            </div>
        </div>
    );
});

export default Landing;
