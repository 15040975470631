import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingStore from "booking/store/BookingStore";
import TimeSlotsStore from "booking/store/TimeSlotStore";
import InitialiseBookingUseCase from "./InitialiseBookingUseCase";

export default class ResetBookingUseCase extends AcornUseCase {
    *onExecute() {
        const clientId = BookingStore.get().clientId;
        TimeSlotsStore.reset();
        BookingStore.reset();
        yield InitialiseBookingUseCase.create().execute(clientId);
    }
}
