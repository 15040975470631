import { observer } from "mobx-react-lite";

import React from "react";
import BookingStore, { HomeScreenView } from "../store/BookingStore";
import Login from "./Login";
import Profile from "./profile/Profile";
import VerifyCode from "./VerifyCode";

const HomeScreen = observer(() => {
    const store = BookingStore.get();

    switch (store.homeScreenView) {
        case HomeScreenView.Login:
            return <Login onHomeScreen />;
        case HomeScreenView.VerifyCode:
            return <VerifyCode onHomeScreen />;
    }

    return <Profile />;
});

export default HomeScreen;
