import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import BookingStore, { BookingStep } from "../store/BookingStore";
import BookingWizard from "../components/booking-wizard/BookingWizard";
import InvitationExpired from "booking/components/booking-wizard/steps/InvitationExpired";
import SelectAppointmentTypeAndEventCodeUseCase from "booking/use-cases/SelectAppointmentTypeAndEventCodeUseCase";
import { useParams } from "react-router";
import { appointmentTypes } from "common";

const InvitationBookingScreen = observer(() => {
    const store = BookingStore.get();
    const params: { token: string } = useParams();

    useEffect(() => {
        if (params.token) {
            const setToken = async () => {
                store.setInvitationToken(params.token);
                await store.getInvitationDetails();
                await SelectAppointmentTypeAndEventCodeUseCase.create().execute(
                    Number(appointmentTypes.clinicalAssessment),
                    ""
                );
            };
            setToken();
        }
    }, [params.token, store]);

    switch (store.step) {
        case BookingStep.InvitationExpired:
            return <InvitationExpired />;
        default:
            return <BookingWizard />;
    }
});

export default InvitationBookingScreen;
