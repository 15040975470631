import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { GlobalStore, InitialiseUseCase } from "@gcdtech/acorn-react-core";

type InitialisationContainerProps = {
    LoadingScreen: React.ComponentType;
    ErrorScreen: React.ComponentType;
    App: React.ComponentType;
};

// In future we could explore passing a Store into this component to implement InitialisationContainer within individual
// modules - for now the Global store with Global Initialisation functions will suffice.
const InitialisationContainer = observer(
    ({ LoadingScreen, ErrorScreen, App }: InitialisationContainerProps): JSX.Element => {
        const store = GlobalStore.get();

        useEffect(() => {
            InitialiseUseCase.create().execute();
        }, []);

        if (store.error) return <ErrorScreen />;

        if (store.initialising || !store.initialised) return <LoadingScreen />;

        return <App />;
    }
);

export { InitialisationContainer };
