import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import TimeSlotStore from "../../../store/TimeSlotStore";
import { Button, Input } from "morse-react";
import { Dialog } from "morse-react-dialogs";
import { AppointmentLocation } from "common";

interface AppointmentFilterProps {
    open: boolean;
    onClose: () => void;
    onApplyFilters: (locations: AppointmentLocation[]) => void;
}

const AppointmentFilter = observer((props: AppointmentFilterProps) => {
    const { open, onClose, onApplyFilters } = props;

    const [filteredLocations, setFilteredLocations] = useState<AppointmentLocation[]>([]);

    const timeSlotStore = TimeSlotStore.get();

    const { locationsWithAppointments } = timeSlotStore;

    if (!open) return null;

    return (
        <Dialog
            onDismissed={() => onClose()}
            allowDismiss
            title={"Filter appointments"}
            buttons={[
                <Button
                    key={"show results"}
                    className="u-width-100pc"
                    onClick={async () => {
                        onApplyFilters(filteredLocations);
                    }}
                >
                    Show results
                </Button>,
            ]}
        >
            <>
                <p className="c-label u-marg-bottom-small">Filter by location</p>
                <div className="c-checklist">
                    {locationsWithAppointments &&
                        locationsWithAppointments.map((appointmentLocation) => {
                            const icon = appointmentLocation.location
                                .toLocaleLowerCase()
                                .includes("bus")
                                ? "bus"
                                : "home";
                            return (
                                <label
                                    key={appointmentLocation.id}
                                    className="u-pad-left-right u-border-darken"
                                    htmlFor={appointmentLocation.location}
                                >
                                    <Input
                                        onClick={() => {
                                            if (filteredLocations.includes(appointmentLocation)) {
                                                setFilteredLocations(
                                                    filteredLocations.filter(
                                                        (fl) => fl !== appointmentLocation
                                                    )
                                                );
                                            } else {
                                                setFilteredLocations([
                                                    ...filteredLocations,
                                                    appointmentLocation,
                                                ]);
                                            }
                                        }}
                                        type="checkbox"
                                        name="checkboxes"
                                        id={appointmentLocation.location}
                                        value={appointmentLocation.location}
                                        defaultChecked={filteredLocations.includes(
                                            appointmentLocation
                                        )}
                                    />
                                    <span>
                                        <span className="u-flex u-align-items-center u-justify-content-start">
                                            <svg className="c-icon u-font-xl u-marg-right-small">
                                                <use href={`/symbol-defs.svg#icon-${icon}-alt`} />
                                            </svg>
                                            <span className="u-font-medium u-block">
                                                {appointmentLocation.location}
                                            </span>
                                        </span>
                                    </span>
                                </label>
                            );
                        })}
                </div>
            </>
        </Dialog>
    );
});

export default AppointmentFilter;
