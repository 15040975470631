import { makeObservable, observable } from "mobx";
import { inject, injectable } from "inversify";
import { asyncAction, LoadableStore } from "@gcdtech/acorn-react-core";
import dayjs, { Dayjs } from "dayjs";
import { TimeSlot, AppointmentLocation } from "common";
import { BOOKING_TYPES } from "booking/ioc/BookingTypes";
import BookingService from "booking/services/BookingService";
import BookingStore from "./BookingStore";

/**
 * Stores the state required to handle appointments presentation
 *
 * Note that the selected appointment is contained in the BookingStore
 * which is the master store for all the confirmed top level choices
 * for the booking.
 */
@injectable()
export default class TimeSlotsStore extends LoadableStore {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    /**
     * The service the user selects if they are going down the public appointment route
     */
    selectedAppointmentTypeId?: number;

    /**
     * The private event code the user inputs if they are going down the private event route
     */
    eventCode?: string;

    /**
     * A list of dates that contain appointments for the current criteria
     */
    datesWithAppointments: Dayjs[] = [];

    /**
     * A list of locations that contain appointments for the current criteria
     */
    locationsWithAppointments: AppointmentLocation[];

    /**
     * The date selected by the user
     */
    selectedDate?: Dayjs;

    /**
     * A list of appointments for the current date and criteria
     */
    timeslots: TimeSlot[];

    /**
     * An error that is set when failed to fetch appointments
     */
    error: boolean;

    constructor() {
        super();

        makeObservable(this, {
            selectedAppointmentTypeId: observable,
            eventCode: observable,
            datesWithAppointments: observable,
            locationsWithAppointments: observable,
            timeslots: observable,
            error: observable,
        });
    }

    public filterAppointmentsByLocation = asyncAction(function* (
        this: TimeSlotsStore,
        locations: AppointmentLocation[]
    ) {
        const locationIds = locations.map(({ id }) => id);
        try {
            const bookingStore = BookingStore.get();
            
            this.datesWithAppointments = yield this.service.getDatesWithTimeSlots(
                this.selectedAppointmentTypeId ?? 0,
                this.eventCode ?? "",
                bookingStore.clientId ?? "",
                locationIds
            );
            this.timeslots = yield this.service.getTimeSlots(
                this.selectedAppointmentTypeId ?? 0,
                this.eventCode ?? "",
                this.selectedDate ?? dayjs(),
                bookingStore.clientId ?? "",
                locationIds
            );
            this.error = false;
        } catch (error) {
            this.error = true;
        }
    });
}
