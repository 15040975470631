import dayjs, { Dayjs } from "dayjs";
import React, { ComponentProps, useEffect, useState } from "react";

export type CountdownProps = ComponentProps<"div"> & {
    expiresAt: Dayjs;
    warningThresholdSeconds?: number;
    alertThresholdSeconds?: number;
    timeLimitSeconds: number;
};

enum ColourRating {
    info = "green",
    warning = "orange",
    alert = "red",
}

// https://css-tricks.com/how-to-create-an-animated-countdown-timer-with-html-css-and-javascript/
// Arcs Length = 2πr = 2 * π * 45 = 282,6
const FullDashArray = "283";

export const Countdown = (props: CountdownProps) => {
    const [timeLeft, setTimeLeft] = useState<number>(props.timeLimitSeconds);
    const [circleDashArray, setCircleDashArray] = useState<string>(FullDashArray);
    const [remainingPathColour, setRemainingPathColour] = useState<string>(ColourRating.info);
    const warningThresholdSeconds = props.warningThresholdSeconds ?? props.timeLimitSeconds / 2;
    const alertThresholdSeconds = props.alertThresholdSeconds ?? warningThresholdSeconds / 2;

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = dayjs();
            const timeLeft = props.expiresAt.diff(currentTime, "second");

            if (timeLeft > 0) {
                setTimeLeft(timeLeft);
            }

            let rawTimeFraction =
                Math.min(Math.max(timeLeft, 0), props.timeLimitSeconds) / props.timeLimitSeconds;
            let timeFraction =
                rawTimeFraction - (1 / props.timeLimitSeconds) * (1 - rawTimeFraction);

            setCircleDashArray(
                `${(timeFraction * Number(FullDashArray)).toFixed(0)} ${FullDashArray}`
            );

            if (timeLeft <= alertThresholdSeconds!) {
                setRemainingPathColour(ColourRating.alert);
            } else if (timeLeft <= warningThresholdSeconds!) {
                setRemainingPathColour(ColourRating.warning);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [warningThresholdSeconds, alertThresholdSeconds, props.expiresAt, props.timeLimitSeconds]);

    function formatTime(time: number) {
        const minutes = Math.floor(time / 60);
        let seconds = String(time % 60);

        if (Number(seconds) < 10) {
            seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
    }

    return (
        <div className="base-timer">
            <svg
                className="base-timer_svg"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g className="base-timer_circle">
                    <circle className="base-timer_path-elapsed" cx="50" cy="50" r="45"></circle>
                    <path
                        id="base-timer-path-remaining"
                        strokeDasharray={circleDashArray}
                        className={`base-timer_path-remaining ${remainingPathColour}`}
                        d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
                    ></path>
                </g>
            </svg>
            <span id="base-timer-label" className="base-timer_label">
                {formatTime(timeLeft)}
            </span>
        </div>
    );
};
