import "./boot";
import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import { BrowserRouter, Switch, Route as BrowserRoute, Link, Redirect } from "react-router-dom";
import CenteredMessage from "./core/components/CenteredMessage";
import { InitialisationContainer } from "core/components/InitialisationContainer";
import { observer } from "mobx-react-lite";
import { PortalContainer } from "morse-react";
import BookingScreen from "booking/screens/BookingScreen";
import HomeScreen from "booking/components/HomeScreen";
import MyDetails from "booking/components/MyDetails";
import BookingLayout from "booking/layout/BookingLayout";
import InvitationBookingScreen from "booking/screens/InvitationBookingScreen";

const Loading = () => {
    return <CenteredMessage smallMessage="Loading booking system" showLoader />;
};

const Error = () => (
    <CenteredMessage icon="⏳" bigMessage="Oops!" smallMessage="Something went wrong" />
);

const App = observer(() => {
    return (
        // The PortalContainer wraps the application and provides an injection point for
        // morse portal using components and hooks, like the useDialog hook family.
        <PortalContainer>
            <BrowserRouter>
                <BookingLayout>
                    <Switch>
                        <BrowserRoute path="/" exact={true}>
                            <Redirect from="/" to="/booking" />
                        </BrowserRoute>

                        <BrowserRoute path="/booking" exact={true}>
                            <BookingScreen />
                        </BrowserRoute>

                        <BrowserRoute path="/invitation/:token" exact={true}>
                            <InvitationBookingScreen />
                        </BrowserRoute>

                        <BrowserRoute path="/account" exact={true}>
                            <HomeScreen />
                        </BrowserRoute>

                        <BrowserRoute path="/register" exact={true}>
                            <MyDetails backwardsTo="/booking" forwardsTo="/account" />
                        </BrowserRoute>

                        <BrowserRoute
                            key="404"
                            render={() => (
                                <CenteredMessage
                                    icon="🔍"
                                    bigMessage="This page doesn't exist"
                                    smallMessage="Check the address, or you can return to the home page."
                                >
                                    <Link to="/booking" className="c-button">
                                        Go home
                                    </Link>
                                </CenteredMessage>
                            )}
                        />
                    </Switch>
                </BookingLayout>
            </BrowserRouter>
        </PortalContainer>
    );
});

// Initial Render
ReactDOM.render(
    <InitialisationContainer LoadingScreen={Loading} ErrorScreen={Error} App={App} />,
    document.getElementById("root")
);
