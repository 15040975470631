import React from "react";

import { observer } from "mobx-react-lite";
import BookingStore from "booking/store/BookingStore";
import { CounsellingBookingData, TypeLabels } from "common";
import { Formik, Field, ErrorMessage } from "formik";
import { Label, Select } from "morse-react";
import * as yup from "yup";
import { InputYesNo } from "core/components/generic/InputYesNo";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";

const CounsellingInformation = observer(() => {
    const store = BookingStore.get();
    const bookingData = store.bookingData as CounsellingBookingData;

    const onSubmit = async (values: Partial<CounsellingBookingData>) => {
        await SubmitBookingUseCase.create().execute({ ...bookingData, ...values });
    };

    return (
        <Formik
            initialValues={{
                attendedWithinLast6Months: bookingData.attendedWithinLast6Months,
                receivingTherapyFromOtherOrganisation:
                    bookingData.receivingTherapyFromOtherOrganisation,
                specialRequirements: bookingData.specialRequirements,
                userTypeID: bookingData.userTypeID,
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object({
                attendedWithinLast6Months: yup.bool().required("Please select Yes or No"),
                receivingTherapyFromOtherOrganisation: yup
                    .bool()
                    .required("Please select Yes or No"),
                specialRequirements: yup.bool().required("Please select Yes or No"),
                userTypeID: yup.number().required("Please choose an option"),
            })}
            validateOnChange={false}
        >
            {({ submitForm, handleChange, values, setFieldValue, isSubmitting, errors }) => {
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
                        <div className="l-app__item +middle">
                            <div className="u-height-100pc u-pad">
                                <div className="u-flex u-align-items-center u-justify-content-center">
                                    <div className="o-wrap u-width-login">
                                        <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                                            <div className="u-pad-large">
                                                <p className="u-font-bold u-font-xxl">
                                                    Screening questions
                                                </p>
                                                <div
                                                    className={`c-field u-marg-bottom ${
                                                        errors.attendedWithinLast6Months
                                                            ? "+error"
                                                            : ""
                                                    }`}
                                                >
                                                    <Field
                                                        name="attendedWithinLast6Months"
                                                        component={InputYesNo}
                                                        label="Have you attended Action Cancer for Counselling within the past 6 months?"
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "attendedWithinLast6Months",
                                                                e.target.value === "true"
                                                            )
                                                        }
                                                        required
                                                    />
                                                </div>
                                                <div
                                                    className={`c-field u-marg-bottom ${
                                                        errors.receivingTherapyFromOtherOrganisation
                                                            ? "+error"
                                                            : ""
                                                    }`}
                                                >
                                                    <Field
                                                        name="receivingTherapyFromOtherOrganisation"
                                                        component={InputYesNo}
                                                        label="Are you currently receiving Counselling with another organisation?"
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "receivingTherapyFromOtherOrganisation",
                                                                e.target.value === "true"
                                                            )
                                                        }
                                                        required
                                                    />
                                                </div>
                                                <div
                                                    className={`c-field u-marg-bottom ${
                                                        errors.specialRequirements ? "+error" : ""
                                                    }`}
                                                >
                                                    <Field
                                                        name="specialRequirements"
                                                        component={InputYesNo}
                                                        label="Do you have any special requirements/disabilities that Action Cancer should be aware of:"
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "specialRequirements",
                                                                e.target.value === "true"
                                                            )
                                                        }
                                                        required
                                                    />
                                                </div>
                                                <div
                                                    className={`c-field u-marg-bottom ${
                                                        errors.userTypeID ? "+error" : ""
                                                    }`}
                                                >
                                                    <Label className="u-marg-none">
                                                        Please tell us how you found out about our
                                                        service:
                                                    </Label>
                                                    <Select
                                                        initialOptions={Object.entries(
                                                            TypeLabels
                                                        ).map(([value, label]) => ({
                                                            label,
                                                            value,
                                                        }))}
                                                        value={TypeLabels[values.userTypeID!]}
                                                        fill
                                                        onChange={(selected?: string): void => {
                                                            setFieldValue(
                                                                "userTypeID",
                                                                Number(selected)
                                                            );
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        component="span"
                                                        name={"userTypeID"}
                                                        className="c-field__feedback"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="l-app__item +end">
                            <div className="u-pad u-fill-white u-border-top">
                                <div className="u-flex c-footer">
                                    <button
                                        type="button"
                                        className="c-button +quiet u-marg-right u-marg-bottom-small"
                                        onClick={() => {
                                            store.setEnteringPersonalDetails(true);
                                        }}
                                    >
                                        <svg className="c-icon u-font-xl u-marg-right-small">
                                            <use href="/symbol-defs.svg#icon-arrow-left" />
                                        </svg>
                                        Back
                                    </button>

                                    <button
                                        type="submit"
                                        className="c-button u-fill-1 +primary u-marg-bottom-small"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Book Appointment
                                        <svg className="c-icon u-font-xl u-marg-left-small">
                                            <use href="/symbol-defs.svg#icon-check" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
});

export default CounsellingInformation;
