import React from "react";
import { useField } from "formik";
import { useRef } from "react";

export const DatePickerField = (props) => {
    const [field] = useField(props);
    const inputRef = useRef();

    return <input {...field} {...props} type="date" ref={inputRef} />;
};
