import BookingStore from "booking/store/BookingStore";
import ListDoctorsUseCase from "booking/use-cases/ListDoctorsUseCase";
import {
    appointmentTypes,
    ContactDetails,
    CountyLabel,
    Doctor,
    County,
    howYouHeardAboutUsLabels,
    ContactPreference,
} from "common";
import { Gender, Title, TrustLabels } from "common/build/users/User";
import CIDLabel from "core/components/generic/CIDLabel";
import dayjs from "dayjs";
import { ErrorMessage, Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Input, InputValue, SelectOptionProps } from "morse-react";
import { Select } from "morse-react";
import { SelectValueProps } from "morse-react/dist/components/select/SelectValue";
import React, { useState } from "react";
import yup from "core/utils/yup-extended";
import { AgeValidationError } from "./booking-wizard/steps/validation/AgeValidation";
import ProfileStore from "../store/ProfileStore";
import { DatePickerField } from "./DatePicker";
import { useHistory } from "react-router";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";
import { BookingData } from "common";
import InitialiseBookingUseCase from "booking/use-cases/InitialiseBookingUseCase";
import { ContactOptions } from "./ContactOptions";
import { useOk } from "morse-react-dialogs";
import LogoutUseCase from "booking/use-cases/authentication/LogoutUseCase";

/**
 * It is worth stating that the design here is sub optimal. This component was designed
 * to be just a step of the booking process like all the others, using the mobx store
 * to update state which would control the visible step. However late in the project
 * this component became reused as a standalone 'registration' which needed to work
 * differently. The props here are optional - if using MyDetails on it's own you can
 * supply URLs to be take to on success, or backwards to if you hit back.
 */
type MyDetailsProps = {
    forwardsTo?: string;
    backwardsTo?: string;
};

const MyDetails = observer((props: MyDetailsProps) => {
    const store = BookingStore.get();
    const profileStore = ProfileStore.get();

    if (store.bookingData && !store.bookingData.contactDetails) {
        const contactDetails = new ContactDetails();
        store.bookingData.contactDetails = contactDetails;
    }

    if (store.bookingData && !store.bookingData.contactPreference) {
        const contactPreferences = new ContactPreference();
        store.bookingData.contactPreference = contactPreferences;
    }

    if (!store.bookingData) {
        const clientId = BookingStore.get().clientId;
        InitialiseBookingUseCase.create().execute(clientId);
    }

    const contactDetails = store.bookingData.contactDetails;
    const contactPreference = store.bookingData.contactPreference;

    const [ageValidationError, showAgeValidationError] = useState<boolean>(false);
    const [gpDetails, setGpDetails] = useState<{
        item?: Doctor;
        label?: string;
        value?: InputValue;
    }>();
    const history = useHistory();

    const formatNumber = (number: string): string => {
        let formattedNumber: string = number.replace("+44", "0");

        if (formattedNumber.slice(0, 2) === "44") {
            formattedNumber = `0${formattedNumber.slice(2)}`;
        }

        return formattedNumber;
    };

    const formatPostcode = (postcode: string): string => {
        //Ensure postcode contains a space
        postcode = postcode.replace(/\s+/g, "");
        postcode =
            postcode.substring(0, postcode.length - 3) +
            " " +
            postcode.substring(postcode.length - 3);
        //Ensure postcode is uppercase
        postcode = postcode.toUpperCase();
        return postcode;
    };

    const formatString = (value: string): string => {
        if (value.length) {
            //Split string into each word
            const words = value.split(" ");
            //Capitalise first letter and make the rest lower case
            const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
            //Rejoin the words into a single string again and remove any leading/trailing spaces
            const formattedValue = formattedWords.join(" ").trim();
            
            return formattedValue;
        }
        return value;
    }

    const formatDetails = (values: ContactDetails): ContactDetails => {
        const formattedValues: ContactDetails = {
            ...values,
            firstName: formatString(values.firstName),
            surname: formatString(values.surname),
            maidenName: values.maidenName && formatString(values.maidenName),
            preferredName: formatString(values.preferredName),
            address: formatString(values.address),
            address2: values.address2 && formatString(values.address2),
            town: formatString(values.town),
            postCode: formatPostcode(values.postCode),
            email: values.email.toLowerCase(),
            country: "1"
        };
        return formattedValues;
    }

    const onSubmit = async (values: ContactDetails) => {
        values = formatDetails(values);
        await store.updateAndSetContactDetails(values);

        if (
            profileStore.loggedIn &&
            (store.timeSlotStore.selectedAppointmentTypeId === appointmentTypes.stepIntoAction ||
                store.timeSlotStore.selectedAppointmentTypeId ===
                    appointmentTypes.clinicalAssessment)
        ) {
            const bookingData = store.bookingData as BookingData;
            await SubmitBookingUseCase.create().execute({ ...bookingData });
        }

        if (
            !store.timeSlotStore.selectedAppointmentTypeId &&
            !store.personalDetailsErrorMessage &&
            props.forwardsTo
        ) {
            history.push(props.forwardsTo);
        }
    };

    const getDoctors = (delay: number) => async (query: string) => {
        await new Promise((resolve) => setTimeout(resolve, delay));

        await ListDoctorsUseCase.create().execute(query);

        return store.doctors
            ? store.doctors.map((value) => {
                  return {
                      item: value,
                      label: `Dr. ${value.forename} ${value.surname}`,
                      value: value.id,
                  };
              })
            : [];
    };

    const DoctorOption = (props: SelectOptionProps<Doctor>) => {
        return (
            <div className="u-pad-top-small u-pad-bottom-small u-justify-content-between">
                <span style={{ lineHeight: "24px" }} className="u-font-medium u-block">
                    {props.multiple && (
                        <input
                            className="c-input"
                            type="checkbox"
                            checked={props.isSelected}
                            readOnly
                        />
                    )}
                    {`Dr. ${props.option.item?.forename} ${props.option.item?.surname}` ||
                        props.option.value}
                </span>
                <span>
                    <span className="u-block u-color-navy">
                        {props.option.item?.practice.addressLine1}
                        <br />
                        {props.option.item?.practice.postcode}
                    </span>
                </span>
            </div>
        );
    };

    const DoctorValue = (props: SelectValueProps<Doctor, InputValue>) => {
        const { children } = props;
        const label = gpDetails?.label ?? children;
        const doctor = props.option.item ?? gpDetails?.item!;
        return (
            <div className="u-pad-top-small u-pad-bottom-small">
                <span style={{ lineHeight: "24px" }} className="u-font-medium u-block">
                    {label}
                </span>
                <span>
                    <span className="u-block u-color-navy">
                        {doctor.practice.addressLine1} <br /> {doctor.practice.postcode}
                    </span>
                </span>
            </div>
        );
    };

    const isAgeValidForSelectedAppointmentType = (age: number): boolean => {
        switch (store.timeSlotStore.selectedAppointmentTypeId) {
            case appointmentTypes.breastScreening:
                if ((age >= 40 && age <= 49) || age >= 70) {
                    return true;
                } else {
                    return false;
                }
            case appointmentTypes.healthCheck:
                if (age < 18) {
                    return false;
                } else {
                    return true;
                }
            case appointmentTypes.stepIntoAction:
                if (age < 18) {
                    return false;
                } else {
                    return true;
                }
        }
        return true;
    };

    const contactPreferenceBlurb = useOk({
        title: "Contact Preference",
        message: (
            <span>
                <p>
                    We know many people like to be kept up-to-date on our services, events and
                    appeals.
                </p>
                <br />
                <p>
                    Tell us how you’d prefer to hear from us and we will occasionally be in touch.
                </p>
                <br />
                <p>You can change your choices at any time.</p>
            </span>
        ),
    });

    const onLogOut = async () => {
        await LogoutUseCase.create().execute();
        await store.returnToLanding();
    };

    const nameRegex = /^[A-Za-z' -]+(?<![ -])$/;
    
    return (
        <Formik
            initialValues={{
                title: contactDetails.title ? contactDetails.title : "",
                firstName: contactDetails.firstName.trim(),
                surname: contactDetails.surname.trim(),
                maidenName: contactDetails.maidenName?.trim(),
                preferredName: contactDetails.preferredName.trim(),
                dob: dayjs(contactDetails.dob).format("YYYY-MM-DD"),
                gender: contactDetails.gender ? contactDetails.gender : "",
                email: contactDetails.email,
                telephone: contactDetails.telephone,
                address: contactDetails.address,
                address2: contactDetails.address2,
                town: contactDetails.town,
                countyId:
                    contactDetails.county && contactDetails.county.id
                        ? contactDetails.county.id
                        : County.Antrim,
                postCode: contactDetails.postCode,
                mobile: contactDetails.mobile,
                trust: contactDetails.trust ? contactDetails.trust : 0,
                gpId: contactDetails.gp && contactDetails.gp.id ? contactDetails.gp.id : 0,
                gp: contactDetails.gp,
                marketingEmail: contactPreference.email,
                marketingPhone: contactPreference.phone,
                marketingPost: contactPreference.post,
                marketingSMS: contactPreference.sms,
                aboutUs: contactPreference.howDidYouHearAboutUs,
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape(
                {
                    title: yup.string().notOneOf([undefined], "Please select a Title"),
                    aboutUs: yup
                        .string()
                        .notOneOf([undefined], "Please select how you heard about us"),
                    firstName: yup
                        .string()
                        .matches(nameRegex, "Please enter valid first name")
                        .max(100)
                        .required("First Name is required"),
                    maidenName: yup
                        .string()
                        .matches(nameRegex, "Please enter valid maiden name")
                        .max(100)
                        .nullable(),
                    preferredName: yup
                        .string()
                        .matches(nameRegex, "Please enter valid preferred name")
                        .max(100)
                        .required("Preferred Name is required"),
                    adddress2: yup
                        .string()
                        .matches(/^[A-Za-z ]*$/, "Please enter valid address")
                        .max(100)
                        .nullable(),
                    surname: yup
                        .string()
                        .matches(nameRegex, "Please enter valid surname")
                        .max(100)
                        .required("Surname is required"),
                    email: yup.string().email().required("Email is required"),
                    dob: yup
                        .string()
                        .test("dob", "Not Eligible for this service", (value) => {
                            let isValidAge = false;

                            if (value) {
                                isValidAge = isAgeValidForSelectedAppointmentType(
                                    dayjs().diff(dayjs(value), "year")
                                );
                            }

                            showAgeValidationError(!isValidAge);

                            return isValidAge;
                        })
                        .test("dob", "Please enter a valid date of birth", (value) => {
                            if (!value) {
                                return false;
                            }
                            const age = dayjs().diff(dayjs(value), "year");
                            const validAge = age > 0 && age < 120;
                            return validAge;
                        })
                        .required("Please select a date of birth"),
                    gender: yup.string().notOneOf([undefined], "Please select a Gender"),
                    mobile: yup
                        .string()
                        .phone("Please enter valid contact number")
                        .required("A contact number is required"),
                    telephone: yup.string().phone("Please enter valid contact number").nullable(),
                    address: yup.string().required("Address Line 1 is required"),
                    town: yup.string().required("Town is required"),
                    countyId: yup.number().notOneOf([0]).required("Please select a County"),
                    postCode: yup
                        .string()
                        .postcode("Please enter valid postcode")
                        .required("Postcode is required"),
                    trust: yup
                        .number()
                        .notOneOf([0], "Please select a Trust")
                        .required("Please select a Trust"),
                    gpId: yup
                        .number()
                        .notOneOf([0], "Please select a Doctor")
                        .required("Please select a Doctor"),
                },
                [["mobile", "telephone"]]
            )}
            isInitialValid={true}
            validateOnMount={false}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ submitForm, values, handleChange, setFieldValue, isSubmitting, errors }) => {
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
                        {store.personalDetailsErrorMessage && (
                            <div className="c-alert u-justify-content-start u-marg-bottom">
                                <div>
                                    <p className="u-font-medium u-marg-bottom-small">
                                        We couldn't {profileStore.loggedIn ? "update" : "create"}{" "}
                                        your account.
                                    </p>
                                    <p className="u-marg-none">
                                        {store.personalDetailsErrorMessage}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="l-app__item +middle">
                            <div className="u-flex u-align-items-center u-justify-content-center u-pad">
                                <div className="o-wrap u-width-2">
                                    <div className="u-border u-shadow-ambient u-fill-white u-rounded">
                                        <div className="u-pad-large u-border-bottom">
                                            <h1 className="u-font-bold u-font-xxl u-marg-bottom">
                                                My details
                                            </h1>
                                            {profileStore.loggedIn && (
                                                <div className="u-border u-rounded u-pad u-marg">
                                                    <span>
                                                        This appointment can only be made for{" "}
                                                        <b>
                                                            {contactDetails.firstName +
                                                                " " +
                                                                contactDetails.surname}
                                                        </b>
                                                    </span>
                                                    <Button
                                                        className="c-button +text +neg +link u-pad-none"
                                                        onClick={onLogOut}
                                                    >
                                                        If this appointment is for someone else
                                                        click here
                                                    </Button>
                                                </div>
                                            )}
                                            <h2 className="u-uppercase u-translucent u-marg-bottom u-font-bold">
                                                About you
                                            </h2>
                                            <div className="u-pad-none u-marg-top-small">
                                                <div className="u-marg-bottom-small">
                                                    <fieldset className="u-pad-none c-field-group +stacked +fill">
                                                        <div
                                                            className={`c-field ${
                                                                errors.title ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                required
                                                                className={"u-marg-none"}
                                                            >
                                                                Title
                                                            </CIDLabel>
                                                            <Field
                                                                name="title"
                                                                as="select"
                                                                className="c-select"
                                                            >
                                                                <option
                                                                    key={0}
                                                                    value=""
                                                                    selected
                                                                    disabled
                                                                >
                                                                    Please select an option
                                                                </option>
                                                                {Object.entries(Title).map(
                                                                    ([value, label]) => (
                                                                        <option
                                                                            key={value}
                                                                            value={value}
                                                                        >
                                                                            {label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </Field>
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"title"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                        <div
                                                            className={`c-field ${
                                                                errors.firstName ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                required
                                                                className={"u-marg-none"}
                                                                htmlFor={"firstName"}
                                                            >
                                                                First Name
                                                            </CIDLabel>
                                                            <Input
                                                                className="+text +fill"
                                                                id={"firstName"}
                                                                name={"firstName"}
                                                                value={values.firstName}
                                                                onChange={handleChange("firstName")}
                                                                required
                                                                disabled={
                                                                    !!contactDetails.firstName
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"firstName"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                        <div
                                                            className={`c-field ${
                                                                errors.surname ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                required
                                                                className={"u-marg-none"}
                                                                htmlFor={"surname"}
                                                            >
                                                                Last Name
                                                            </CIDLabel>
                                                            <Input
                                                                className="+text +fill"
                                                                name={"surname"}
                                                                id={"surname"}
                                                                value={values.surname}
                                                                onChange={handleChange("surname")}
                                                                required
                                                            />
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"surname"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                        <div
                                                            className={`c-field ${
                                                                errors.maidenName ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                optional
                                                                htmlFor={"maidenName"}
                                                            >
                                                                Maiden name
                                                            </CIDLabel>
                                                            <Input
                                                                className="+text +fill"
                                                                name={"maidenName"}
                                                                id={"maidenName"}
                                                                value={values.maidenName}
                                                                onChange={handleChange(
                                                                    "maidenName"
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"maidenName"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                        <div
                                                            className={`c-field ${
                                                                errors.preferredName ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                required
                                                                htmlFor={"preferredName"}
                                                            >
                                                                Preferred name
                                                            </CIDLabel>
                                                            <Input
                                                                className="+text +fill"
                                                                name={"preferredName"}
                                                                id={"preferredName"}
                                                                value={values.preferredName}
                                                                onChange={handleChange(
                                                                    "preferredName"
                                                                )}
                                                                required
                                                            />
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"preferredName"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div
                                                className={`c-field ${errors.dob ? "+error" : ""}`}
                                            >
                                                <CIDLabel required htmlFor={"dob"}>
                                                    Date of Birth
                                                </CIDLabel>
                                                <DatePickerField
                                                    name="dob"
                                                    className="c-input +text"
                                                    style={{ display: "block" }}
                                                    placeholder={"1975-12-31"}
                                                    defaultValue={
                                                        values.dob
                                                            ? values.dob
                                                            : undefined
                                                    }
                                                    value={
                                                        values.dob
                                                            ? values.dob
                                                            : undefined
                                                    }
                                                    onChange={(e) => {
                                                        setFieldValue("dob", e.target.value);
                                                    }}
                                                    required
                                                    disabled={!!contactDetails.dob}
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"dob"}
                                                    className="c-field__feedback"
                                                />
                                            </div>

                                            <div
                                                className={`c-field ${
                                                    errors.gender ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel required className={"u-marg-none"}>
                                                    Gender
                                                </CIDLabel>
                                                <Field
                                                    name="gender"
                                                    as="select"
                                                    className="c-select"
                                                >
                                                    <option key={0} value="" selected disabled>
                                                        Please select an option
                                                    </option>
                                                    {Object.entries(Gender).map(
                                                        ([value, label]) => (
                                                            <option key={label} value={label}>
                                                                {value}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    component="span"
                                                    name={"gender"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="u-pad-large u-border-bottom">
                                            <h2 className="u-uppercase u-translucent u-marg-bottom u-font-bold">
                                                Address
                                            </h2>
                                            <div
                                                className={`c-field ${
                                                    errors.address ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel required htmlFor={"address"}>
                                                    Address Line 1
                                                </CIDLabel>
                                                <Input
                                                    className="+text +fill"
                                                    name={"address"}
                                                    id={"address"}
                                                    value={values.address}
                                                    onChange={handleChange("address")}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"address"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                            <div
                                                className={`c-field ${
                                                    errors.address2 ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel htmlFor={"address2"}>
                                                    Address Line 2
                                                </CIDLabel>
                                                <Input
                                                    className="+text +fill"
                                                    id={"address2"}
                                                    name={"address2"}
                                                    value={values.address2}
                                                    onChange={handleChange("address2")}
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"address2"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                            <div
                                                className={`c-field ${errors.town ? "+error" : ""}`}
                                            >
                                                <CIDLabel required htmlFor={"town"}>
                                                    Town
                                                </CIDLabel>
                                                <Input
                                                    className="+text +fill"
                                                    id={"town"}
                                                    name={"town"}
                                                    value={values.town}
                                                    onChange={handleChange("town")}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"town"}
                                                    className="c-field__feedback"
                                                />
                                            </div>

                                            <div
                                                className={`c-field ${
                                                    errors.countyId ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel required>County</CIDLabel>
                                                <Field
                                                    name="countyId"
                                                    as="select"
                                                    className="c-select"
                                                    onChange={(e): void => {
                                                        return setFieldValue(
                                                            "countyId",
                                                            parseInt(e.target.value)
                                                        );
                                                    }}
                                                >
                                                    {Object.entries(CountyLabel).map(
                                                        ([value, label]) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    component="span"
                                                    name={"countyId"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                            <div
                                                className={`c-field ${
                                                    errors.postCode ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel htmlFor={"postCode"} required>
                                                    Postcode
                                                </CIDLabel>
                                                <Input
                                                    className="+text"
                                                    id={"postCode"}
                                                    name={"postCode"}
                                                    value={values.postCode}
                                                    onChange={handleChange("postCode")}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"postCode"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="u-pad-large u-border-bottom">
                                            <h2 className="u-uppercase u-translucent u-marg-bottom u-font-bold">
                                                Contact Details
                                            </h2>
                                            <div
                                                className={`c-field ${
                                                    errors.email ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel htmlFor={"email"} required>
                                                    Email
                                                </CIDLabel>
                                                <Input
                                                    className="+text +fill"
                                                    id={"email"}
                                                    name={"email"}
                                                    value={values.email}
                                                    type={"email"}
                                                    onChange={handleChange("email")}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"email"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                            <div
                                                className={`c-field ${
                                                    errors.telephone ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel htmlFor={"telephone"} required>
                                                    Home Phone
                                                </CIDLabel>
                                                <Input
                                                    className="+text +fill"
                                                    id={"telephone"}
                                                    name={"telephone"}
                                                    value={values.telephone}
                                                    type={"tel"}
                                                    onBlur={(e) => {
                                                        setFieldValue(
                                                            "telephone",
                                                            formatNumber(e.target.value)
                                                        );
                                                    }}
                                                    onChange={handleChange("telephone")}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name="telephone"
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                            <div
                                                className={`c-field ${
                                                    errors.mobile ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel htmlFor={"mobile"} required>
                                                    Mobile
                                                </CIDLabel>
                                                <Input
                                                    className="+text +fill"
                                                    id={"mobile"}
                                                    name={"mobile"}
                                                    type={"tel"}
                                                    value={values.mobile}
                                                    onBlur={(e) => {
                                                        setFieldValue(
                                                            "mobile",
                                                            formatNumber(e.target.value)
                                                        );
                                                    }}
                                                    onChange={handleChange("mobile")}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"mobile"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="u-pad-large u-border-bottom">
                                            <h2 className="u-uppercase u-translucent u-marg-bottom u-font-bold">
                                                Your Doctor
                                            </h2>
                                            <div
                                                className={`c-field ${
                                                    errors.trust ? "+error" : ""
                                                }`}
                                            >
                                                <CIDLabel required>Trust</CIDLabel>
                                                <Field
                                                    name="trust"
                                                    as="select"
                                                    className="c-select u-width-100pc"
                                                    onChange={(e): void => {
                                                        return setFieldValue(
                                                            "trust",
                                                            parseInt(e.target.value)
                                                        );
                                                    }}
                                                >
                                                    <option key={0} value={0} selected disabled>
                                                        Please select an option
                                                    </option>
                                                    {Object.entries(TrustLabels).map(
                                                        ([value, label]) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    component="span"
                                                    name={"trust"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                            <div
                                                className={`c-field ${errors.gpId ? "+error" : ""}`}
                                            >
                                                <CIDLabel required>Doctor</CIDLabel>
                                                <Select
                                                    autoFocus={false}
                                                    components={{
                                                        Option: DoctorOption,
                                                        Value: DoctorValue,
                                                    }}
                                                    initialOptions={
                                                        values.gp
                                                            ? [
                                                                  {
                                                                      item: values.gp as Doctor,
                                                                      label: `Dr. ${values.gp.forename} ${values.gp.surname}`,
                                                                      value: values.gpId,
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                    searchable={true}
                                                    onChange={(value, object) => {
                                                        setGpDetails({
                                                            item: object?.item,
                                                            value: object?.value,
                                                            label: object?.label?.toString(),
                                                        });
                                                        return setFieldValue("gpId", value);
                                                    }}
                                                    noOptionsText={
                                                        "Start typing your doctors name to begin searching"
                                                    }
                                                    fill
                                                    value={values.gp ? values.gpId : undefined}
                                                    name={"gpId"}
                                                    refreshOptionsOnSearch
                                                    getOptions={getDoctors(500)}
                                                    hideLoader={true}
                                                />
                                                <ErrorMessage
                                                    component="span"
                                                    name={"gpId"}
                                                    className="c-field__feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="u-pad-large">
                                            <div
                                                className={`c-field ${
                                                    errors.aboutUs ? "+error" : ""
                                                }`}
                                            >
                                                <h2 className="u-uppercase u-translucent u-marg-bottom u-font-bold">
                                                    Contact Preferences
                                                    <span className="u-hide">More information</span>
                                                    <button
                                                        title="Click here for more information"
                                                        style={{
                                                            cursor: "pointer",
                                                            verticalAlign: "sub",
                                                        }}
                                                        type="button"
                                                        className="c-button +text"
                                                        onClick={() =>
                                                            contactPreferenceBlurb.show()
                                                        }
                                                    >
                                                        <svg className="c-icon u-font-xl">
                                                            <use href="/symbol-defs.svg#icon-question-circle" />
                                                        </svg>
                                                    </button>
                                                </h2>
                                                <ContactOptions />
                                                <fieldset className="u-pad-none u-marg-top u-width-100pc">
                                                    <CIDLabel required>
                                                        How did you hear about us?
                                                    </CIDLabel>
                                                    <Field
                                                        name="aboutUs"
                                                        as="select"
                                                        className="c-select u-width-100pc"
                                                        onChange={(e): void => {
                                                            return setFieldValue(
                                                                "aboutUs",
                                                                parseInt(e.target.value)
                                                            );
                                                        }}
                                                    >
                                                        <option value="" disabled selected>
                                                            Please select an option
                                                        </option>
                                                        {Object.entries(
                                                            howYouHeardAboutUsLabels
                                                        ).map(([value, label]) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                        component="span"
                                                        name={"aboutUs"}
                                                        className="c-field__feedback"
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <>
                            {ageValidationError && (
                                <AgeValidationError
                                    open={ageValidationError}
                                    onClose={() => showAgeValidationError(false)}
                                    appointmentTypeId={
                                        store.timeSlotStore.selectedAppointmentTypeId
                                    }
                                />
                            )}
                        </>
                        <div className="l-app__item +end">
                            <div className="u-pad u-fill-white u-border-top">
                                <div className="u-flex c-footer">
                                    {!profileStore.loggedIn && (
                                        <>
                                            <button
                                                type="button"
                                                className="c-button +quiet u-marg-right"
                                                onClick={() => {
                                                    store.setEnteringPersonalDetails(false);
                                                    if (props.backwardsTo) {
                                                        history.push(props.backwardsTo);
                                                    }
                                                }}
                                            >
                                                <svg className="c-icon u-font-xl u-marg-right-small">
                                                    <use href="/symbol-defs.svg#icon-arrow-left" />
                                                </svg>
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="c-button u-fill-1 +primary"
                                                onClick={submitForm}
                                            >
                                                Register
                                                <svg className="c-icon u-font-xl u-marg-left-small">
                                                    <use href="/symbol-defs.svg#icon-check" />
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                    {profileStore.loggedIn && (
                                        <>
                                            <button
                                                type="button"
                                                className="c-button +quiet u-marg-right"
                                                onClick={async () => {
                                                    await store.returnToSelectAppointment();
                                                    store.setEnteringPersonalDetails(false);

                                                    if (props.backwardsTo) {
                                                        history.push(props.backwardsTo);
                                                    }
                                                }}
                                            >
                                                <svg className="c-icon u-font-xl u-marg-right-small">
                                                    <use href="/symbol-defs.svg#icon-arrow-left" />
                                                </svg>
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="c-button u-fill-1 +primary"
                                                onClick={submitForm}
                                                disabled={isSubmitting}
                                            >
                                                {profileStore.loggedIn &&
                                                (store.timeSlotStore.selectedAppointmentTypeId ===
                                                    appointmentTypes.stepIntoAction ||
                                                    store.timeSlotStore
                                                        .selectedAppointmentTypeId ===
                                                        appointmentTypes.clinicalAssessment)
                                                    ? "Book Appointment"
                                                    : "Continue"}
                                                <svg className="c-icon u-font-xl u-marg-left-small">
                                                    <use href="/symbol-defs.svg#icon-arrow-right" />
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
});

export default MyDetails;
