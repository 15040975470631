import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "morse-react";
import { Dialog } from "morse-react-dialogs";

interface ConfirmNewBookingProps {
    open: boolean;
    loggedInUserName: string;
    onDismissed: () => void;
    onConfirm: () => void;
    onDeny: () => void;
}

const ConfirmNewBooking = observer((props: ConfirmNewBookingProps) => {
    if (!props.open) return null;

    return (
        <Dialog
            title={"Book New Appointment for " + props.loggedInUserName}
            allowDismiss
            onDismissed={props.onDismissed}
            buttons={[
                <Button key={"confirm"} className="u-width-100pc" onClick={props.onConfirm}>
                    Yes - Continue
                </Button>,
                <Button key={"deny"} className="u-width-100pc +quiet" onClick={props.onDeny}>
                    No - Book for Someone Else
                </Button>,
            ]}
        >
            <div>
                <p className="u-marg-bottom">
                    You are booking a new appointment for {props.loggedInUserName}.<br />
                    Is this correct?
                </p>
            </div>
        </Dialog>
    );
});

export default ConfirmNewBooking;
