import React, { PropsWithChildren } from "react";

import { observer } from "mobx-react-lite";
import { Dialog } from "morse-react-dialogs";
import { Button } from "morse-react";

type CIDErrorModalProps = PropsWithChildren<{
    heading: string;
    open: boolean;
    onClose: () => void;
}>;

export const CIDErrorModal = observer((props: CIDErrorModalProps) => {
    const { heading, children, open, onClose } = props;

    if (!open) {
        return null;
    }

    return (
        <Dialog
            onDismissed={() => onClose()}
            allowDismiss
            title={heading}
            buttons={[
                <Button key={"close"} className="u-width-100pc" onClick={onClose}>
                    Close
                </Button>,
            ]}
        >
            {children}
        </Dialog>
    );
});

export default CIDErrorModal;
