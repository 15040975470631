import React from "react";

import { observer } from "mobx-react-lite";
import BookingStore, { BookingWizardStep } from "../../store/BookingStore";
import { CIDTabbedWizard } from "core/components/wizard/CIDTabbedWizard";
import { CIDWizardStep } from "core/components/wizard/CIDWizardStep";
import MyDetails from "../MyDetails";
import Login from "../Login";
import VerifyCode from "../VerifyCode";
import BreastScreeningInformation from "./steps/BreastScreeningInformation";
import ComplementaryTherapyInformation from "./steps/ComplementaryTherapyInformation";
import CounsellingInformation from "./steps/CounsellingInformation";
import HealthCheckInformation from "./steps/HealthCheckInformation";
import SkinAssessmentInformation from "./steps/SkinAssessmentInformation";
import SelectAppointment from "./steps/SelectAppointment";
import BookingConfirmation from "./steps/BookingConfirmation";
import { AuthenticationType } from "common";
import { appointmentTypes } from "common";

const BookingWizard = observer(() => {
    const store = BookingStore.get();

    function renderRequiredInformation() {
        switch (store.timeSlotStore.selectedAppointmentTypeId!) {
            case appointmentTypes.breastScreening:
                return <BreastScreeningInformation />;
            case appointmentTypes.complementaryTherapy:
                return <ComplementaryTherapyInformation />;
            case appointmentTypes.counselling:
                return <CounsellingInformation />;
            case appointmentTypes.healthCheck:
                return <HealthCheckInformation />;
            case appointmentTypes.virtualAssessment:
                return <SkinAssessmentInformation />;
        }
    }

    const requiredInfoElement = renderRequiredInformation();
    return (
        <div className="l-app +y +gap-0">
            <CIDTabbedWizard
                goToStep={(targetStepId) => {}}
                currentStepId={store.wizardStep}
                tabContainerClassName="l-app__item +start"
            >
                <CIDWizardStep
                    identifier={BookingWizardStep.SelectDate}
                    key={BookingWizardStep.SelectDate}
                    heading={{ title: BookingWizardStep.SelectDate }}
                >
                    <SelectAppointment />
                </CIDWizardStep>
                <CIDWizardStep
                    identifier={BookingWizardStep.Login}
                    key={BookingWizardStep.Login}
                    heading={{ title: BookingWizardStep.Login }}
                >
                    {store.authenticatingBy !== AuthenticationType.None && store.loginCredential ? (
                        <VerifyCode />
                    ) : (
                        <Login />
                    )}
                </CIDWizardStep>
                <CIDWizardStep
                    identifier={BookingWizardStep.MyDetails}
                    key={BookingWizardStep.MyDetails}
                    heading={{ title: BookingWizardStep.MyDetails }}
                >
                    <MyDetails />
                </CIDWizardStep>
                {requiredInfoElement! && (
                    <CIDWizardStep
                        identifier={BookingWizardStep.RequiredInformation}
                        key={BookingWizardStep.RequiredInformation}
                        heading={{ title: BookingWizardStep.RequiredInformation }}
                    >
                        {requiredInfoElement}
                    </CIDWizardStep>
                )}
                <CIDWizardStep
                    identifier={BookingWizardStep.AppointmentConfirmed}
                    key={BookingWizardStep.AppointmentConfirmed}
                    heading={{ title: BookingWizardStep.AppointmentConfirmed }}
                >
                    <BookingConfirmation />
                </CIDWizardStep>
            </CIDTabbedWizard>
        </div>
    );
});

export default BookingWizard;
