import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { default as ReactCalendar } from "react-calendar";
import dayjs, { Dayjs } from "dayjs";
import Calendar from "core/components/calendar/Calendar";
import DateSelector from "core/components/calendar/DateSelector";
import { AppointmentLocation, TimeSlot, appointmentTypes } from "common";
import TimeSlotStore from "../../../store/TimeSlotStore";
import BookingStore from "../../../store/BookingStore";
import SelectDateUseCase from "../../../use-cases/SelectDateUseCase";
import { Loader } from "morse-react";
import SelectAndLockAppointmentUseCase from "booking/use-cases/SelectAndLockAppointmentUseCase";
import { useOk } from "morse-react-dialogs";
import AppointmentFilter from "./AppointmentFilter";
import ProfileStore from "../../../store/ProfileStore";
import { renderServiceDetails } from "./AppointmentInfo";
import ResetBookingUseCase from "booking/use-cases/ResetBookingUseCase";

const SelectAppointment = observer(() => {
    const bookingStore = BookingStore.get();
    const profileStore = ProfileStore.get();
    const timeSlotStore = TimeSlotStore.get();

    const [currentlySelectedSlot, setCurrentlySelectedSlot] = useState<TimeSlot>();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);

    /*  TODO: Discussions
        - Do all time slots have a standard duration or is this specific to each apt type - can we fetch this from the api?
   */

    const { datesWithAppointments, timeslots, error } = timeSlotStore;

    const unableToSelectSlot = useOk({
        title: "This appointment is no longer available",
        message: (
            <>
                <p className="u-marg-bottom">
                    We couldn't secure your selected appointment. This may be due to:{" "}
                </p>
                <ul className="u-bullet-list u-marg-bottom">
                    <li>Spending too long on this screen</li>
                    <li>Our system has timed out</li>
                    <li>Somebody else booked this appointment</li>
                </ul>
                <p className="u-marg-bottom">Please choose another appointment.</p>
            </>
        ),
    });

    const onSlotSelected = async () => {
        if (currentlySelectedSlot) {
            try {
                if (profileStore.loggedIn) {
                    timeSlotStore.selectedDate = currentlySelectedSlot.date;
                    await bookingStore.populateMyDetails();
                    bookingStore.setEnteringPersonalDetails(true);
                }
                await SelectAndLockAppointmentUseCase.create().execute(currentlySelectedSlot);
            } catch (e) {
                unableToSelectSlot.show();
            }
        }
    };

    const onDateSelected = async (date: Dayjs) => {
        setFetching(true);
        await SelectDateUseCase.create().execute(date);
        setFetching(false);
    };

    let selectedDate = timeSlotStore.selectedDate;
    if (!selectedDate) {
        /*  Sorts dates array in ascending order & sets the selected date to the first available
            OR defaults to today,
            Note: We need to use .slice() here to create a shallow copy
            of our datesWithAppointments observable here before sorting 
            https://mobx.js.org/api.html#observablearray
        */
        const sortedAppointments = datesWithAppointments
            .slice()
            .sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
        selectedDate =
            sortedAppointments && sortedAppointments.length > 0 ? sortedAppointments[0] : dayjs();
        onDateSelected(selectedDate);
    }

    const onNewMonthSelected = async (date: Dayjs) => {
        const newMonthAppointents = datesWithAppointments.filter((dateWithAppointment: Dayjs) => {
            return dateWithAppointment.get("month") === date.get("month");
        });

        newMonthAppointents.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
        const firstAvailableDate =
            newMonthAppointents && newMonthAppointents.length > 0
                ? newMonthAppointents[0]
                : undefined;
        onDateSelected(firstAvailableDate || date.startOf("month"));
    };

    const addCalendarClasses = (dateNotification) => {
        const day = dayjs(dateNotification.date);
        const dayString = dateNotification.date.toDateString();
        // Dates in the past will not get a notification set.
        let classes = "";
        // If the date is in the future
        if (
            datesWithAppointments &&
            datesWithAppointments.length > 0 &&
            day.format("YY/MM/DD").toString() >= dayjs().format("YY/MM/DD").toString()
        ) {
            // We will add a notification alert if there are slots available
            classes += datesWithAppointments.filter(
                (appointment) => dayString === appointment.toDate().toDateString()
            ).length
                ? "has-notification"
                : "";
        }

        classes +=
            day.toDate().toDateString() === selectedDate!.toDate().toDateString()
                ? " is-active"
                : "";

        return classes;
    };

    const disableTile = ({ date, view }: { date: Date; view: string }) => {
        const dayString = date.toDateString();
        let disableBool = true;
        if (datesWithAppointments && datesWithAppointments.length > 0) {
            datesWithAppointments.forEach((slot) => {
                if (view === "month" && dayString === slot.toDate().toDateString()) {
                    disableBool = false;
                } else if (view === "year" && date.getMonth() === slot.get("month")) {
                    disableBool = false;
                }
            });
        }
        return disableBool;
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
            {bookingStore.submissionErrorMessage && (
                <div className="c-alert u-justify-content-start u-marg-bottom">
                    <div>
                        <p className="u-font-medium u-marg-bottom-small">Booking issue.</p>
                        <p className="u-marg-none">{bookingStore.submissionErrorMessage}</p>
                    </div>
                </div>
            )}
            <div className="l-app__item +middle u-height-100pc">
                <div className="l-wrap u-width-4">
                    <div className="c-calendar__sticky u-fill-site">
                        {/*Scrollable Date Selector*/}
                        <DateSelector
                            loading={fetching}
                            firstDate={selectedDate.startOf("month")}
                            value={selectedDate}
                            onDateSelected={(date) => {
                                onDateSelected(date);
                            }}
                            onNextMonth={() => {
                                onNewMonthSelected(selectedDate!.add(1, "month"));
                            }}
                            onPreviousMonth={() => {
                                onNewMonthSelected(selectedDate!.subtract(1, "month"));
                            }}
                            slots={datesWithAppointments}
                            maxDate={dayjs().add(1, "year")}
                        />
                    </div>
                    <div className="l-grid +gap-2 u-pad">
                        <div className="l-grid__item +span-3 u-none@m- c-calendar__sticky">
                            <ReactCalendar
                                className="is-active"
                                minDate={dayjs().toDate()}
                                minDetail={"year"}
                                defaultValue={dayjs().toDate()}
                                activeStartDate={selectedDate.startOf("month").toDate()}
                                maxDate={dayjs().add(1, "year").toDate()}
                                value={selectedDate ? selectedDate.toDate() : undefined}
                                onChange={(value: Date) => {
                                    onDateSelected(dayjs(value));
                                }}
                                onClickMonth={(value: Date) => {
                                    onNewMonthSelected(dayjs(value));
                                }}
                                calendarType={"US"}
                                onActiveStartDateChange={({ activeStartDate }) => {
                                    onNewMonthSelected(dayjs(activeStartDate));
                                }}
                                tileClassName={addCalendarClasses}
                                tileDisabled={disableTile}
                            />
                            <div className="u-border u-rounded u-pad u-marg-top">
                                <p className="u-font-bold u-font-xl u-marg-bottom u-flex u-align-items-center">
                                    <svg className="c-icon u-font-xl u-marg-right-small">
                                        <use href="/symbol-defs.svg#icon-calendar-alt" />
                                    </svg>
                                    Booking appointments online
                                </p>
                                {timeSlotStore.selectedAppointmentTypeId &&
                                timeSlotStore.selectedAppointmentTypeId ===
                                    appointmentTypes.breastScreening ? (
                                    <p className="u-marg-bottom">
                                        To book an appointment you must be a full time resident of
                                        Northern Ireland and be registered with a GP here, this is
                                        to ensure we have a care pathway for you, should something
                                        show up on your Mammogram.
                                        <br />
                                        (We can offer an appointment to some residents of the border
                                        counties in ROI, please ring our appointments line and the
                                        team can assess eligibility{" "}
                                        <a href="tel:02890803344">
                                            <u>02890 803 344</u>
                                        </a>
                                        ).
                                    </p>
                                ) : (
                                    <p className="u-marg-bottom">
                                        Online bookings are only available to residents of Northern
                                        Ireland. If you live outside Northern Ireland please book
                                        your appointment through our appointment line on{" "}
                                        <a href="tel:02890803344">
                                            <u>02890 803 344</u>
                                        </a>
                                        .
                                    </p>
                                )}
                                {timeSlotStore.selectedAppointmentTypeId &&
                                timeSlotStore.selectedAppointmentTypeId ===
                                    appointmentTypes.virtualAssessment && (
                                    <p className="u-marg-bottom">
                                        Please do not book an appointment if you have recently
                                        seen your GP or have already been referred to
                                        Dermatology.
                                    </p>
                                )}
                                {timeSlotStore.selectedAppointmentTypeId ? (
                                    renderServiceDetails(timeSlotStore.selectedAppointmentTypeId)
                                ) : (
                                    <Loader />
                                )}
                            </div>
                        </div>
                        <div className="l-grid__item +span-3@m +span-6">
                            {selectedDate && (
                                <div className="u-marg-bottom u-flex u-align-items-center u-justify-content-between">
                                    <p className="c-heading +h2 u-font-bold">
                                        {selectedDate.format("dddd D MMMM")}
                                    </p>
                                    <button
                                        type="button"
                                        className="c-button +quiet"
                                        onClick={() => {
                                            setShowFilters(!showFilters);
                                        }}
                                    >
                                        <svg className="c-icon u-font-xl u-marg-right-small">
                                            <use href="/symbol-defs.svg#icon-filter" />
                                        </svg>
                                        Filter by appointment location
                                    </button>
                                </div>
                            )}
                            {!fetching ? (
                                <Calendar
                                    selectedDate={selectedDate}
                                    slots={timeslots}
                                    onSlotSelected={setCurrentlySelectedSlot}
                                    selectedSlot={currentlySelectedSlot}
                                    service={timeSlotStore.selectedAppointmentTypeId}
                                />
                            ) : (
                                <Loader />
                            )}
                            <AppointmentFilter
                                open={showFilters}
                                onClose={() => setShowFilters(false)}
                                onApplyFilters={async (locations: AppointmentLocation[]) => {
                                    setFetching(true);
                                    await timeSlotStore.filterAppointmentsByLocation(locations);
                                    setCurrentlySelectedSlot(undefined);
                                    setShowFilters(false);
                                    setFetching(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-app__item +end">
                {error && (
                    <div className="u-border-top u-marg-top">
                        <div className="c-alert u-marg-left-right u-marg-top u-fill-neg u-bold u-justify-content-start u-align-self-center">
                            <span role="img" aria-label="warning">
                                ⚠️
                            </span>
                            <span className="u-flex-grow-1">Error fetching appointments</span>
                        </div>
                    </div>
                )}
                <div className="u-pad u-fill-white u-border-top">
                    <div className="u-flex c-footer">
                        <button
                            type="button"
                            className="c-button +quiet u-marg-right"
                            onClick={() => {
                                ResetBookingUseCase.create().execute();
                                bookingStore.returnToLanding();
                            }}
                        >
                            <svg className="c-icon u-font-xl u-marg-right-small">
                                <use href="/symbol-defs.svg#icon-arrow-left" />
                            </svg>
                            Back
                        </button>

                        <button
                            type="submit"
                            className="c-button u-fill-1 +primary"
                            onClick={onSlotSelected}
                            disabled={!currentlySelectedSlot}
                        >
                            My Details
                            <svg className="c-icon u-font-xl u-marg-left-small">
                                <use href="/symbol-defs.svg#icon-arrow-right" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
});

export default SelectAppointment;
