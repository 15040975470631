import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import logo from "../../images/logo.png";

export type CenteredMessageProps = {
    icon?: string;
    bigMessage?: string;
    smallMessage?: string;
    showLoader?: boolean;
};

const CenteredMessage: React.FC<PropsWithChildren<CenteredMessageProps>> = observer(
    ({ icon, bigMessage, smallMessage, showLoader, children }) => {
        return (
            <div className="u-flex u-align-items-center u-justify-content-center u-height-100vh u-max-height-100pc">
                <div className="u-rounded u-fill-white u-border u-shadow-ambient u-pad-large u-align-center">
                    <img
                        src={logo}
                        alt="Logo"
                        style={{ width: "165px" }}
                        className="u-marg-bottom u-pull-center"
                    />
                    {bigMessage && <p className="u-font-xxl">{bigMessage}</p>}
                    {smallMessage && <p className="u-font-large">{smallMessage}</p>}
                    {showLoader && (
                        <div className="u-pad-top u-flex u-justify-content-center">
                            <div className="c-loader u-font-xxl"></div>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        );
    }
);

export default CenteredMessage;
