import React from "react";

import BookingStore from "booking/store/BookingStore";
import ResetBookingUseCase from "booking/use-cases/ResetBookingUseCase";
import { observer } from "mobx-react-lite";
import { appointmentTypeLabels } from "common";
import FetchProfileUseCase from "../../../use-cases/FetchProfileUseCase";
import { Link, useHistory } from "react-router-dom";

const BookingConfirmation = observer(() => {
    const store = BookingStore.get();
    const history = useHistory();

    const onReturnHome = async () => {
        await FetchProfileUseCase.create().execute();
        await ResetBookingUseCase.create().execute();
        history.push("/account");
    };

    return (
        <div onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
            <div className="l-app__item +middle">
                <div className="u-height-100pc u-pad">
                    <div className="u-flex u-align-items-center u-justify-content-center u-height-100pc">
                        <div className="o-wrap u-width-login">
                            <div className="u-border u-align-center u-rounded u-fill-white u-shadow-ambient">
                                <div className="u-pad-large">
                                    <p className="u-font-bold u-font-xxl">Booking confirmed</p>
                                    <p className="u-marg-bottom">
                                        Thank you, we have received your booking. You should receive
                                        a confirmation email shortly.
                                    </p>
                                    <div className="u-align-left u-border u-rounded u-pad u-marg-bottom">
                                        <p className="u-font-medium">
                                            {store.timeSlotStore.selectedDate?.format(
                                                "dddd D MMMM"
                                            )}{" "}
                                            {store.timeSlotStore.selectedDate?.format("h:mma") !==
                                            "12:00am"
                                                ? "at " +
                                                  store.timeSlotStore.selectedDate?.format("h:mma")
                                                : ""}
                                        </p>
                                        <p className="u-marg-bottom-small">
                                            <span className="u-color-navy">
                                                {
                                                    appointmentTypeLabels[
                                                        store.timeSlotStore
                                                            .selectedAppointmentTypeId!
                                                    ]
                                                }
                                            </span>{" "}
                                            at{" "}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: store.appointmentLocation,
                                                }}
                                                className="u-color-navy"
                                            />
                                        </p>
                                        &nbsp;
                                        <a
                                            target={"_blank"}
                                            rel="noopener noreferrer"
                                            href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
                                                store.appointmentLocation
                                            )}`}
                                            className=""
                                        >
                                            <u>Get directions</u>
                                        </a>
                                    </div>
                                    <p className="u-marg-bottom">You can now close this window.</p>
                                    <Link to="/account">
                                        <button
                                            type="submit"
                                            className="c-button u-fill-1 u-width-100pc u-marg-bottom-small"
                                            onClick={onReturnHome}
                                        >
                                            Home
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BookingConfirmation;
