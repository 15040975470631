import { AcornUseCase } from "@gcdtech/acorn-react-core";
import AppointmentNotLockedError from "booking/errors/AppointmentNotLockedError";
import { BOOKING_TYPES } from "booking/ioc/BookingTypes";
import BookingService from "booking/services/BookingService";
import BookingStore from "booking/store/BookingStore";
import { TimeSlot } from "common";
import { inject } from "inversify";

/**
 * Attempts to lock the given appointment and promote it to the selected appointment
 *
 * This involves a service call to ensure the server has locked it successfully. As
 * a result the appointment will be updated to include the lock end date/time.
 *
 * This use case can throw an exception if the locking fails.
 *
 */
export default class SelectAndLockAppointmentUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    async *onExecute(appointment: TimeSlot) {
        try {
            // As the service to lock the appointment. We expect a new appointment object back
            // one that has lockedUntil set for 15 minutes into the future.
            const refreshedAppointment = yield await this.service.lockAppointment(
                appointment.id,
                BookingStore.get().clientId
            );

            BookingStore.get().selectedTimeSlot = {
                ...refreshedAppointment,
                location: appointment.location,
                locationDescription: appointment.locationDescription,
                address: appointment.address,
            };
        } catch (e) {
            throw new AppointmentNotLockedError(appointment);
        }
    }
}
