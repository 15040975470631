import React from "react";
import { Field } from "formik";
import CIDLabel from "core/components/generic/CIDLabel";

export const ContactOptions = () => {
    return (
        <table className="c-table +padded-cells">
            <thead>
                <tr>
                    <th className="u-align-center"></th>
                    <th className="u-align-center">Email</th>
                    <th className="u-align-center">SMS</th>
                    <th className="u-align-center">Phone</th>
                    <th className="u-align-center">Post</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <CIDLabel>How would you like to be contacted?</CIDLabel>
                    </td>
                    <td>
                        <div className="c-checklist +solo">
                            <label>
                                <Field type="checkbox" name="marketingEmail" />
                                <span></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="c-checklist +solo">
                            <label>
                                <Field type="checkbox" name="marketingSMS" />
                                <span></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="c-checklist +solo">
                            <label>
                                <Field type="checkbox" name="marketingPhone" />
                                <span></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="c-checklist +solo">
                            <label>
                                <Field type="checkbox" name="marketingPost" />
                                <span></span>
                            </label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
