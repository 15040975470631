import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";

type CIDWizardStepProps = PropsWithChildren<{
    heading: { title: string; icon?: string; showStepNumber?: boolean };
    identifier: string;
    className?: string;
    disabled?: boolean;
}>;

export const CIDWizardStep = observer((props: CIDWizardStepProps) => {
    window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, are you sure?";
    };
    return <>{props.children}</>;
});

export default CIDWizardStep;
