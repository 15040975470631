import React from "react";

export const SpecialRequirementsWarning = () => {
    return (
        <div className="u-border u-rounded u-pad u-marg-top">
            <p className="u-font-bold u-font-xl u-marg-bottom u-flex u-align-items-center">
                Contact Us
            </p>
            <p className="u-marg-bottom">
                Please ring Action Cancer on
                <a href="tel:02890803344">
                    <u>028 9080 3344</u>
                </a>{" "}
                before you make this booking to discuss your special requirements.
            </p>
        </div>
    );
};

export const AlreadyReceivingTherapyError = () => {
    return (
        <div className="u-border u-rounded u-pad u-marg-top">
            <p className="u-font-bold u-font-xl u-marg-bottom u-flex u-align-items-center">Sorry</p>
            <p className="u-marg-bottom u-bold">
                We are sorry, but we cannot offer this service to clients who are currently
                receiving it elsewhere, as it may not be beneficial to you. However, please contact
                our appointments desk on
                <a href="tel:02890803344">
                    <u>028 9080 3344</u>
                </a>{" "}
                to discuss the other support services we can offer you.
            </p>
        </div>
    );
};

export const AttendedInTheLast6MonthsError = () => {
    return (
        <div className="u-border u-rounded u-pad u-marg-top">
            <p className="u-font-bold u-font-xl u-marg-bottom u-flex u-align-items-center">Sorry</p>
            <p className="u-marg-bottom u-bold">
                We are sorry, but we do not usually offer additional sessions to clients who have
                attended within 6 months. Please contact our appointments desk on
                <a href="tel:02890803344">
                    <u>028 9080 3344</u>
                </a>{" "}
                to discuss the potential of other support services we can offer you.
            </p>
        </div>
    );
};
