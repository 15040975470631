import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { ErrorMessage, FieldProps } from "formik";
import { Input } from "morse-react";
import CIDLabel from "./CIDLabel";

type InputYesNoProps = {
    fieldSetClassName?: string;
    errorMessageClassName?: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    checked?: boolean;
    disabled?: boolean;
};

export const InputYesNo = observer((props: InputYesNoProps & FieldProps) => {
    const {
        fieldSetClassName,
        field,
        onChange,
        label,
        errorMessageClassName,
        required,
        checked,
        disabled,
    } = props;

    const [checkedYes, setCheckedYes] = useState(checked === true);
    const [checkedNo, setCheckedNo] = useState(checked === false);

    const handleChange = (e) => {
        setCheckedYes(e.target.value === "true");
        setCheckedNo(e.target.value === "false");
        onChange(e);
    }

    return (
        <fieldset className="u-pad-none u-marg-top-small">
            <div>
                {label && (
                    <CIDLabel className={`u-marg-none ${fieldSetClassName}`} required={required}>
                        {label}
                    </CIDLabel>
                )}
                <div className="c-checklist +center">
                    <label>
                        <Input
                            type="radio"
                            name={field.name}
                            value="true"
                            onChange={handleChange}
                            checked={checkedYes}
                            disabled={disabled}
                        />
                        <span>Yes</span>
                    </label>
                    <label>
                        <Input
                            type="radio"
                            name={field.name}
                            value="false"
                            onChange={handleChange}
                            checked={checkedNo}
                            disabled={disabled}
                        />
                        <span>No</span>
                    </label>
                </div>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className={`c-field__feedback ${errorMessageClassName}`}
                />
            </div>
        </fieldset>
    );
});

export default InputYesNoProps;
