import { AcornUseCase } from "@gcdtech/acorn-react-core";
import { BOOKING_TYPES } from "booking/ioc/BookingTypes";
import BookingService from "booking/services/BookingService";
import BookingStore from "booking/store/BookingStore";
import TimeSlotsStore from "booking/store/TimeSlotStore";
import { Dayjs } from "dayjs";
import { inject, injectable } from "inversify";

/**
 * Sets the user's selected date and loads the corresponding slots for that date
 *
 * Calls the appropriate service method depending on whether or not an event code
 * or service type is in play.
 */
@injectable()
export default class SelectDateUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(date: Dayjs) {
        const store = TimeSlotsStore.get();
        const bookingStore = BookingStore.get();

        store.selectedDate = date;

        // Pivot on whether or not we have an eventCode and call the correct service method.
        // Note that eventCode will be reset if SelectServiceUseCase is called
        store.timeslots = yield this.service.getTimeSlots(
            store.selectedAppointmentTypeId ?? 0,
            store.eventCode ?? "",
            date,
            bookingStore.clientId,
            []
        );
    }
}
