import { inject } from "inversify";
import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingService from "../services/BookingService";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import BookingStore from "booking/store/BookingStore";

/**
 * Retrieves all the availables GPs
 */
export default class ListDoctorsUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(query) {
        const bookingStore = BookingStore.get();

        try {
            bookingStore.doctors = yield this.service.getDoctors(query);
        } catch (error) {}
    }
}
