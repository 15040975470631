import React, { PropsWithChildren } from "react";

import { observer } from "mobx-react-lite";
import { Label } from "morse-react";

type CIDLabelProps = PropsWithChildren<{
    className?: string;
    required?: boolean;
    optional?: boolean;
    htmlFor?: string;
}>;

export const CIDLabel = observer((props: CIDLabelProps) => {
    const { className = "", required, optional, children, htmlFor } = props;

    return (
        <Label className={className} htmlFor={htmlFor}>
            {children}&nbsp;
            {required && <span className="u-color-1">*</span>}
            {optional && <span className="u-translucent">(optional)</span>}
        </Label>
    );
});

export default CIDLabel;
