import React from "react";
import { Dayjs } from "dayjs";
import Slots from "./Slots";
import { appointmentTypes, TimeSlot } from "common";
import { observer } from "mobx-react-lite";

export interface CalendarProps {
    selectedDate: Dayjs;
    slots?: TimeSlot[];
    onSlotSelected: (slot: TimeSlot) => void;
    selectedSlot?: TimeSlot;
    service: appointmentTypes | undefined;
}

const Calendars = observer((props: CalendarProps) => {
    const { slots = [], selectedDate, onSlotSelected, selectedSlot, service } = props;
    return (
        <Slots
            slots={slots}
            selectedDate={selectedDate}
            onSlotSelected={onSlotSelected}
            selectedSlot={selectedSlot}
            service={service}
        />
    );
});
export default Calendars;
