/**
 * These are all UK specific
 */

import * as yup from "yup";

const rePhoneNumber = /^(\+44\s?\d{10}|44\s?\d{10}|0\s?\d{10})?$/;

function phoneNumberCorrect(this: yup.StringSchema, message?: string) {
    return this.test({
        name: "phone",
        message: message ?? "Not a valid UK phone number",
        test: (value) => (value ? rePhoneNumber.test(value) : true),
    });
}

yup.addMethod(yup.string, "phone", phoneNumberCorrect);

// HCN/NHS number validation

function validateNhsNumber(nhsNumber: string) {
    let isValid = false;

    if (nhsNumber) {
        nhsNumber = nhsNumber.replace(/\s/g, "");

        if (nhsNumber.length === 10) {
            let total = 0;

            let i;
            for (i = 0; i <= 8; i++) {
                let digit = Number(nhsNumber.substr(i, 1));
                let factor = 10 - i;
                total += digit * factor;
            }

            let checkDigit = 11 - (total % 11);

            if (checkDigit === 11) {
                checkDigit = 0;
            }

            if (checkDigit === Number(nhsNumber.substr(9, 1))) {
                isValid = true;
            }
        }
    }

    return isValid;
}

function nhsNumberCorrect(this: yup.StringSchema, message: string) {
    return this.test({
        name: "nhsNumber",
        message: message ?? "Not a valid Health and Care Number",
        test: (value) => (value ? validateNhsNumber(value) : true),
    });
}

yup.addMethod(yup.string, "nhsNumber", nhsNumberCorrect);

// eslint-disable-next-line max-len
//const rePostcode = /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2}$/;
function postcodeCorrect(this: yup.StringSchema, message: string) {
    return this.test({
        name: "postcode",
        message: "Valid UK postcodes must be at least 6 characters long.",
        test: (value) => (value ? value.replace(" ", "").length >= 6 : true),
    });
}

yup.addMethod(yup.string, "postcode", postcodeCorrect);

declare module "yup" {
    interface StringSchema {
        phone(message: string): StringSchema;
        postcode(message: string): StringSchema;
        nhsNumber(message: string): StringSchema;
    }
}

export default yup;
