import CIDErrorModal from "core/components/generic/CIDErrorModal";
import React from "react";

type SkinAssessmentValidationProps = {
    open: boolean;
    onClose: () => void;
};

export const Over18YearsError = (props: SkinAssessmentValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for Skin Cancer Early Detection at Action Cancer"}
        >
            <p className="u-marg-bottom u-font-bold">
                Who is eligible for Skin Cancer Early Detection at Action Cancer?
            </p>
            <p className="u-marg-bottom">
                You must be over the age of 18 to take part in Skin Cancer Early Detection
            </p>
        </CIDErrorModal>
    );
};

export const ChangingMoleOrLesionError = (props: SkinAssessmentValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for Skin Cancer Early Detection at Action Cancer"}
        >
            <p className="u-marg-bottom u-font-bold">
                Who is eligible for Skin Cancer Early Detection at Action Cancer?
            </p>
            <p className="u-marg-bottom">
                Skin Cancer Early Detection at Action Cancer is only available to those who are
                experiencing a changing or non-healing mole or lesion.
            </p>
            <p className="u-marg-bottom u-font-bold">
                A new or changing/non-healing mole or lesion includes:
            </p>
            <ul className="u-marg-bottom u-bullet-list">
                <li>increasing in size</li>
                <li>changing in shape or colour</li>
                <li>itch</li>
                <li>crusting</li>
                <li>stinging</li>
                <li>bleeding</li>
            </ul>
        </CIDErrorModal>
    );
};

export const NoMobilityIssuesError = (props: SkinAssessmentValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for Skin Cancer Early Detection at Action Cancer"}
        >
            <p className="u-marg-bottom">
                Sorry, you can't use the online booking system to book your appointment. Please ring
                02890803344 to book.
            </p>
        </CIDErrorModal>
    );
};
