import { inject } from "inversify";
import { CORE_TYPES, AcornUseCase, StorageService } from "@gcdtech/acorn-react-core";
import { BOOKING_TYPES } from "../../ioc/BookingTypes";
import ProfileStore from "../../store/ProfileStore";
import TimeSlotsStore from "../../store/TimeSlotStore";
import BookingStore from "../../store/BookingStore";

export default class LogoutUseCase extends AcornUseCase {
    @inject(CORE_TYPES.StorageService) private storageService: StorageService;
    @inject(BOOKING_TYPES.TokenStorageKey) private tokenStorageKey: string;

    // eslint-disable-next-line
    *onExecute() {
        this.storageService.removeItem(this.tokenStorageKey);

        ProfileStore.reset();
        TimeSlotsStore.reset();
        BookingStore.reset();
    }
}
