import React from "react";

import { observer } from "mobx-react-lite";
import { Dialog } from "morse-react-dialogs";
import { Button } from "morse-react";
import { appointmentTypes } from "common";

type AppointmentInfoProps = {
    open: boolean;
    onClose: () => void;
    service: appointmentTypes | undefined;
};

export const AppointmentInfo = observer((props: AppointmentInfoProps) => {
    const { open, onClose, service } = props;

    if (!open) {
        return null;
    }

    return (
        <Dialog
            onDismissed={() => onClose()}
            title={"Booking appointments online"}
            allowDismiss
            buttons={[
                <Button key={"close"} className="u-width-100pc +quiet" onClick={onClose}>
                    Close
                </Button>,
            ]}
        >
            <div>
                {service && service === appointmentTypes.breastScreening ? (
                    <p className="u-marg-bottom">
                        To book an appointment you must be a full time resident of Northern Ireland
                        and be registered with a GP here, this is to ensure we have a care pathway
                        for you, should something show up on your Mammogram.
                        <br />
                        (We can offer an appointment to some residents of the border counties in
                        ROI, please ring our appointments line and the team can assess eligibility{" "}
                        <a href="tel:02890803344">
                            <u>02890 803 344</u>
                        </a>
                        ).
                    </p>
                ) : (
                    <p className="u-marg-bottom">
                        Online bookings are only available to residents of Northern Ireland. If you
                        live outside Northern Ireland please book your appointment through our
                        appointment line on{" "}
                        <a href="tel:02890803344">
                            <u>02890 803 344</u>
                        </a>
                        .
                    </p>
                )}
                {service && service === appointmentTypes.virtualAssessment && (
                    <p className="u-marg-bottom">
                        Please do not book an appointment if you have recently seen your GP or
                        have already been referred to Dermatology.
                    </p>
                )}
                {service && renderServiceDetails(service)}
            </div>
        </Dialog>
    );
});

export function renderServiceDetails(service: appointmentTypes) {
    switch (service) {
        case appointmentTypes.breastScreening:
            return (
                <>
                    <p className="u-marg-bottom">
                        Breast Screening aims to detect cancer in its earlier stages when chances of
                        survival are at their highest.
                    </p>
                    <p className="u-marg-bottom">
                        Action Cancer offers breast screening to women aged 40 - 49 and 70+ who fall
                        outside of the NHS age range (50 - 70).
                    </p>
                    <p className="u-marg-bottom">
                        The service is available in Action Cancer House, 20 Windsor Avenue, Belfast
                        or on the Action Cancer Big Bus which travels across Northern Ireland
                        visiting communities and workplaces.
                    </p>
                </>
            );
        case appointmentTypes.healthCheck:
            return (
                <>
                    <p className="u-marg-bottom">
                        These health check appointments are for men or women aged 18 and over. Our
                        health checks do not diagnose cancer but aim to increase awareness of our
                        current health and the importance of early detection. Each appointment will
                        last around 20 minutes and will include four tests: Blood pressure, glucose,
                        cholesterol and body composition.
                    </p>
                    <p className="u-marg-bottom">Please note the following:</p>
                    <ul className="u-marg-bottom u-bullet-list">
                        <li>
                            A <span className="u-font-bold">three hour fast</span> is necessary
                            before the cholesterol and glucose tests.
                        </li>
                        <li>
                            Cholesterol and glucose tests cannot be carried out if suffering from a
                            bleeding disorder or on blood thinning medication (e.g. Warfarin).
                        </li>
                        <li>
                            Body composition test cannot be carried out if you have any internally
                            implanted medical devices (e.g. pacemaker).
                        </li>
                    </ul>
                    <p>
                        Please complete all of the information below, select your tests and choose
                        an available date and time for your appointment.
                    </p>
                </>
            );
        case appointmentTypes.stepIntoAction:
            return (
                <>
                    <p>
                        Do you want to become healthier, but you get stuck? Do you struggle to eat
                        healthily or get enough exercise? How it works...
                    </p>
                    <p>
                        Up to six 1 to 1 sessions with a highly qualified health and well-being
                        coach. A safe and encouraging space. Behavioural change and self-compassion
                        techniques to help you flourish. Supporting Resources Step into Action
                        appointments are for anyone over the age of 18.
                    </p>
                </>
            );
        default:
            return <></>;
    }
}

export default AppointmentInfo;
