import CIDErrorModal from "core/components/generic/CIDErrorModal";
import React from "react";

type BreastScreeningValidationProps = {
    open: boolean;
    onClose: () => void;
};

export const NonNiResidentError = (props: BreastScreeningValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for screening at Action Cancer"}
        >
            <p className="u-marg-bottom">
                The mammogram results can take up to 3 weeks if something is discovered on your
                mammogram that needs further checks then we refer you to one of the local specialist
                breast clinics within Northern Ireland- they will only accept referrals for people
                who are resident in N.I and registered with a GP here. As we have a duty of care to
                the women we screen we have to ensure that there is a pathway to further
                investigation &amp; treatment for them if needed.
            </p>
            <p className="u-marg-bottom u-bold">
                If you are going on holiday please make you appointment at a time when you will be
                available, If you are not a full time resident of NI please see your GP in your own
                country for advice on how to access screening locally
            </p>
            <p className="u-marg-bottom u-bold">
                NB if you are aged 40-49 or 65+ &amp; from ROI please ring Action Cancer on{" "}
                <a href="tel:02890803344">
                    <u>02890 8033 44</u>
                </a>{" "}
                as special circumstances apply
            </p>
        </CIDErrorModal>
    );
};

export const ConcernsCheckError = (props: BreastScreeningValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for screening at Action Cancer"}
        >
            <p className="u-marg-bottom">
                A breast screening service exists for women without any signs, symptoms or concerns
                and the results of mammogram takes 2-3 weeks
            </p>
            <ul className="u-marg-bottom u-bullet-list">
                <li>If you have a symptom investigation needs to be quick &amp; complete</li>
                <li>
                    Breast clinics exist for this purpose at local NHS hospitals, Your GP should
                    refer you to one of these clinics ASAP
                </li>
                <li>
                    At a One Stop Breast clinic lady would be examined by a breast physician who
                    would refer her for a mammogram/ ultrasound if necessary, needle tests if
                    indicated and usually the majority of results are available on the one day. .
                </li>
                <li>
                    A Mammogram at Action Cancer would only be small part of that picture; 2
                    radiologists must report screening films independently, our results take 2-3
                    weeks. Only then would a lady be referred to a breast clinic, go on a waiting
                    list, be seen and get results. .
                </li>
            </ul>
            <p className="u-marg-bottom u-bold">
                We would advise the following are checked out by GP
            </p>
            <ul className="u-marg-bottom u-bullet-list">
                <li>A lump or thickness in the breast or under the armpit</li>
                <li>Change in the skin – any dimpling, puckering or redness</li>
                <li>Nipple discharge – bleeding or any heavy discharge</li>
                <li>
                    Change in the position of a nipple – pulled inwards or pointing in a different
                    direction
                </li>
                <li>Rash on the nipple</li>
            </ul>
            <p className="u-marg-bottom u-bold">
                We do not consider breast pain a symptom so please click back &amp; go onto next
                stage of booking
            </p>
            <p className="u-marg-bottom">
                Breast pain is very common in women of all ages. For example, approximately two out
                of three pre-menopausal women will experience it at some time in their lives. Having
                breast pain does not increase your risk of breast cancer. However, it is still
                important to be breast aware and go back to your GP if your pain is persistent,
                unusual or you notice any other changes in your breasts.
            </p>
            <p>There are 3 types of breast pain:</p>
            <ul>
                <li>
                    Cyclical breast pain - breast pain that has a clear relationship to the
                    menstrual cycle and the most common type of breast pain.
                </li>
                <li>
                    Non-cyclical breast pain - may be constant or intermittent but is not associated
                    with the menstrual cycle.
                </li>
                <li>
                    Chest Wall (non-breast) pain - is interpreted as having a cause within the
                    breast but arises from elsewhere.
                </li>
            </ul>
            <p className="u-marg-bottom u-bold">
                If you are unsure if you are eligible or not please ring Action Cancer on{" "}
                <a href="tel:02890803344">
                    <u>02890 8033 44</u>
                </a>{" "}
                for further advice
            </p>
        </CIDErrorModal>
    );
};

export const PreviousCheckError = (props: BreastScreeningValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for screening at Action Cancer"}
        >
            <p className="u-marg-bottom">
                Action Cancer recommends a routine screening mammogram every two years for women in
                our age range. Please ring Action Cancer staff to confirm when your next screening
                is due on{" "}
                <a href="tel:02890803344">
                    <u>02890 8033 44</u>
                </a>{" "}
                or choose an appointment date which allows a 2 year gap between screenings.
            </p>
        </CIDErrorModal>
    );
};

export const PregnantOrBreastFeedingError = (props: BreastScreeningValidationProps) => {
    const { open, onClose } = props;
    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for screening at Action Cancer"}
        >
            <p className="u-marg-bottom">
                We do not recommend breast screening for women with no problems during pregnancy due
                to the small risks to baby related to radiation During breastfeeding the breast
                tissue is full difficult to image due to the presence of milk, also as we compress
                the breast during screening this can be very uncomfortable.
            </p>
            <p className="u-marg-bottom">
                We recommend that you wait <span className="u-font-bold">6 months</span> after
                finishing breast feeding before booking your routine mammogram
            </p>
        </CIDErrorModal>
    );
};
