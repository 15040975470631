import { AcornUseCase } from "@gcdtech/acorn-react-core";
import { inject } from "inversify";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import BookingService from "../services/BookingService";
import ProfileStore from "../store/ProfileStore";

export default class FetchProfileUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute() {
        const profileStore = ProfileStore.get();

        profileStore.upcomingAppointments = yield this.service.getUpcomingAppointments();

        const loggedInUserDetails = yield this.service.getAuthenticatedUserContactDetails();

        profileStore.loggedInUserName = loggedInUserDetails.firstName;
    }
}
