import { inject } from "inversify";
import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingService from "../services/BookingService";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import TimeSlotsStore from "booking/store/TimeSlotStore";
import BookingStore from "booking/store/BookingStore";
import { appointmentTypes } from "common";
import dayjs from "dayjs";

/**
 * Sets the chosen service from the user and loads the dates that would be appropriate.
 */
export default class SelectAppointmentTypeAndEventCodeUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(appointmentTypeId: number, eventCode: string) {
        const appointmentsStore = TimeSlotsStore.get();
        const bookingStore = BookingStore.get();

        try {
            appointmentsStore.eventCode = eventCode;
            appointmentsStore.selectedAppointmentTypeId = appointmentTypeId;

            appointmentsStore.datesWithAppointments = yield this.service.getDatesWithTimeSlots(
                appointmentsStore.selectedAppointmentTypeId,
                appointmentsStore.eventCode ?? "",
                bookingStore.clientId
            );

            appointmentsStore.locationsWithAppointments = yield this.service.getLocationsWithTimeSlots(
                appointmentsStore.selectedAppointmentTypeId,
                appointmentsStore.eventCode ?? ""
            );

            if (appointmentTypeId === appointmentTypes.clinicalAssessment) {
                if (
                    appointmentsStore.datesWithAppointments &&
                    appointmentsStore.datesWithAppointments.length > 0
                ) {
                    appointmentsStore.selectedDate = appointmentsStore.datesWithAppointments[0];
                }
                appointmentsStore.timeslots = yield this.service.getTimeSlots(
                    appointmentsStore.selectedAppointmentTypeId,
                    appointmentsStore.eventCode ?? "",
                    appointmentsStore.selectedDate ?? dayjs(),
                    bookingStore.clientId ?? ""
                );
            } else if (appointmentTypeId === appointmentTypes.breastScreening) {
                yield bookingStore.getPreviousAppointmentDate(appointmentTypeId);
            }
            appointmentsStore.error = false;
        } catch (error) {
            appointmentsStore.error = true;
        }
    }
}
