import { inject } from "inversify";
import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingService from "../services/BookingService";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import { Appointment } from "common";
import CancelAppointmentError from "../errors/CancelAppointmentError";
import ProfileStore from "../store/ProfileStore";

/**
 * Cancel the specified appointment, then fetches the
 * upcoming appointments for the user again so refreshing the list.
 *
 * The actual loading/error state will be handled within the HomeScreen
 * component.
 */
export default class CancelAppointmentUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(appointment: Appointment) {
        const profileStore = ProfileStore.get();
        try {
            yield this.service.cancelAppointment(appointment);
            profileStore.upcomingAppointments = yield this.service.getUpcomingAppointments();
        } catch (error) {
            throw new CancelAppointmentError();
        }
    }
}
