import { CORE_TYPES, AcornUseCase, Route } from "@gcdtech/acorn-react-core";
import BookingLayout from "../layout/BookingLayout";
import BookingScreen from "../screens/BookingScreen";
import { ContainerModule, interfaces } from "inversify";

import InitialiseBookingUseCase from "../use-cases/InitialiseBookingUseCase";
import BookingService from "../services/BookingService";
import { BOOKING_TYPES } from "./BookingTypes";
import ApiBookingService from "../services/ApiBookingService";
import InvitationBookingScreen from "booking/screens/InvitationBookingScreen";

export default class BookingModule extends ContainerModule {
    constructor() {
        super((bind: interfaces.Bind) => {
            bind<Route>(CORE_TYPES.Route).toConstantValue({
                type: "component",
                authenticated: false,
                exact: true,
                path: "/booking",
                component: BookingScreen,
                layoutOverride: BookingLayout,
                layoutProps: { pageTitle: "Booking" },
            });
            bind<Route>(CORE_TYPES.Route).toConstantValue({
                type: "component",
                authenticated: false,
                exact: true,
                path: "/invitation/:token",
                component: InvitationBookingScreen,
                layoutOverride: BookingLayout,
                layoutProps: { pageTitle: "Booking" },
            });
            bind<Route>(CORE_TYPES.Route).toConstantValue({
                type: "redirect",
                authenticated: false,
                path: "/",
                exact: true,
                to: "/booking",
            });
            bind<BookingService>(BOOKING_TYPES.BookingService).to(ApiBookingService);
            bind(BOOKING_TYPES.TokenStorageKey).toConstantValue("ApiAuthenticationToken");
            bind<AcornUseCase>(CORE_TYPES.InitialiseUseCase).to(InitialiseBookingUseCase);
        });
    }
}
