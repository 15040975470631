import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import BookingStore from "booking/store/BookingStore";
import { BreastScreeningBookingData } from "common";
import { Formik, ErrorMessage, Field } from "formik";
import { Input } from "morse-react";
import * as yup from "yup";
import { InputYesNo } from "core/components/generic/InputYesNo";
import CIDLabel from "core/components/generic/CIDLabel";
import dayjs from "dayjs";
import {
    ConcernsCheckError,
    NonNiResidentError,
    PregnantOrBreastFeedingError,
    PreviousCheckError,
} from "./validation/BreastScreeningValidation";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";
import { DatePickerField } from "booking/components/DatePicker";

const BreastScreeningInformation = observer(() => {
    const store = BookingStore.get();
    const bookingData = store.bookingData as BreastScreeningBookingData;

    bookingData.nhsNumber = store.bookingData.contactDetails.nhsNumber ?? "";
    bookingData.hadPreviousScan = !!store.previousAppointmentDate;
    bookingData.previousMammogram = store.previousAppointmentDate;

    const [showPreviousMammogramError, setShowPreviousMammogramError] = useState<boolean>(false);
    const [showPregnantOrBreastFeedingError, setShowPregnantOrBreastFeedingError] =
        useState<boolean>(false);
    const [showExistingConcernsError, setShowExistingConcernsError] = useState<boolean>(false);
    const [showNotFromNIError, setShowNotFromNIError] = useState<boolean>(false);

    const onSubmit = async (values: Partial<BreastScreeningBookingData>) => {
        await SubmitBookingUseCase.create().execute({ ...bookingData, ...values });
    };

    const validatePreviousScan = (value: string) => {
        if (dayjs(value).isAfter(dayjs(store.selectedTimeSlot?.date).subtract(2, "year"))) {
            setShowPreviousMammogramError(true);
        } else {
            setShowPreviousMammogramError(false);
        }
    };

    const validateConcerns = (value: string) => {
        setShowExistingConcernsError(value === "true" ? true : false);
    };

    const validateNIResident = (value: string) => {
        setShowNotFromNIError(value === "false" ? true : false);
    };

    const validatePregnantOrBreastFeeding = (value: string) => {
        setShowPregnantOrBreastFeedingError(value === "true" ? true : false);
    };

    return (
        <>
            {showNotFromNIError && (
                <NonNiResidentError
                    open={showNotFromNIError}
                    onClose={() => setShowNotFromNIError(false)}
                />
            )}
            {showPregnantOrBreastFeedingError && (
                <PregnantOrBreastFeedingError
                    open={showPregnantOrBreastFeedingError}
                    onClose={() => setShowPregnantOrBreastFeedingError(false)}
                />
            )}
            {showExistingConcernsError && (
                <ConcernsCheckError
                    open={showExistingConcernsError}
                    onClose={() => setShowExistingConcernsError(false)}
                />
            )}
            {showPreviousMammogramError && (
                <PreviousCheckError
                    open={showPreviousMammogramError}
                    onClose={() => setShowPreviousMammogramError(false)}
                />
            )}
            <Formik
                initialValues={{
                    existingConcerns: bookingData.existingConcerns,
                    hadPreviousScan: bookingData.hadPreviousScan,
                    hadScanElsewhere: bookingData.hadScanElsewhere,
                    northernIrelandResident: bookingData.northernIrelandResident,
                    pregnantOrBreastfeeding: bookingData.pregnantOrBreastfeeding,
                    previousElseWhereMammogram: bookingData.previousElseWhereMammogram,
                    previousMammogram: bookingData.previousMammogram,
                    nhsNumber: bookingData.nhsNumber,
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    existingConcerns: yup
                        .bool()
                        .oneOf([false], "Not Eligible for this service.")
                        .required("Please choose Yes or No"),
                    hadPreviousScan: yup.bool().required("Please choose Yes or No"),
                    hadScanElsewhere: yup.bool().required("Please choose Yes or No"),
                    northernIrelandResident: yup
                        .bool()
                        .oneOf([true], "Not Eligible for this service.")
                        .required("Please choose Yes or No"),
                    pregnantOrBreastfeeding: yup
                        .bool()
                        .oneOf([false], "Not Eligible for this service.")
                        .required("Please choose Yes or No"),
                    previousElseWhereMammogram: yup.date().when("hadScanElsewhere", {
                        is: true,
                        then: yup
                            .date()
                            .max(
                                dayjs(store.selectedTimeSlot?.day).subtract(2, "year").toDate(),
                                "Sorry you aren’t eligible for this service as you can’t have a mammogram within 2 years of your last one."
                            )
                            .required("Please select a date"),
                    }),
                    previousMammogram: yup.date().when("hadPreviousScan", {
                        is: true,
                        then: yup
                            .date()
                            .max(
                                dayjs(store.selectedTimeSlot?.day).subtract(2, "year").toDate(),
                                "Sorry you aren’t eligible for this service as you can’t have a mammogram within 2 years of your last one."
                            )
                            .required("Please select a date"),
                    }),
                    nhsNumber: yup
                        .string()
                        .nhsNumber("Not a valid Health and Care Number")
                        .required("Please enter your Health and Care Number"),
                })}
                validateOnChange={true}
            >
                {({ submitForm, values, handleChange, isSubmitting, errors, setFieldValue }) => {
                    return (
                        <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
                            <div className="l-app__item +middle">
                                <div className="u-height-100pc u-pad">
                                    <div className="u-flex u-align-items-center u-justify-content-center">
                                        <div className="o-wrap u-width-login">
                                            <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                                                <div className="u-pad-large">
                                                    <p className="u-font-bold u-font-xxl">
                                                        Screening questions
                                                    </p>
                                                    <fieldset className="u-pad-none">
                                                        <div
                                                            className={`c-field ${
                                                                errors.nhsNumber ? "+error" : ""
                                                            }`}
                                                        >
                                                            <CIDLabel
                                                                required
                                                                className="u-marg-none"
                                                            >
                                                                Health and Care Number
                                                            </CIDLabel>
                                                            <Input
                                                                className="+text +fill"
                                                                name={"nhsNumber"}
                                                                value={values.nhsNumber}
                                                                onChange={handleChange("nhsNumber")}
                                                            />
                                                            <ErrorMessage
                                                                component="span"
                                                                name={"nhsNumber"}
                                                                className="c-field__feedback"
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.hadPreviousScan ? "+error" : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="hadPreviousScan"
                                                            component={InputYesNo}
                                                            label="Have you been with Action Cancer for Breast Screening before?"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "hadPreviousScan",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                            checked={!!bookingData.hadPreviousScan}
                                                            disabled={!!bookingData.hadPreviousScan}
                                                        />
                                                    </div>
                                                    {String(values.hadPreviousScan) === "true" && (
                                                        <fieldset className="u-pad-none u-marg-bottom">
                                                            <div
                                                                className={`c-field ${
                                                                    errors.previousMammogram
                                                                        ? "+error"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <CIDLabel
                                                                    required
                                                                    className="u-marg-none"
                                                                >
                                                                    Approx date of last mammogram
                                                                    with Action Cancer
                                                                </CIDLabel>
                                                                <DatePickerField
                                                                    name="previousMammogram"
                                                                    className="+text"
                                                                    style={{ display: "block" }}
                                                                    placeholder={"2019-12-31"}
                                                                    defaultValue={
                                                                        values.previousMammogram
                                                                            ? values.previousMammogram
                                                                                  .toString()
                                                                                  .split("T")[0]
                                                                            : undefined
                                                                    }
                                                                    value={
                                                                        values.previousMammogram
                                                                            ? values.previousMammogram
                                                                                  .toString()
                                                                                  .split("T")[0]
                                                                            : undefined
                                                                    }
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "previousMammogram",
                                                                            e.target.value
                                                                        );
                                                                        validatePreviousScan(
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    disabled={!!bookingData.hadPreviousScan}
                                                                />
                                                                <ErrorMessage
                                                                    component="span"
                                                                    name={"previousMammogram"}
                                                                    className="c-field__feedback"
                                                                />
                                                            </div>
                                                        </fieldset>
                                                    )}
                                                    <div
                                                        className={`c-field ${
                                                            errors.hadScanElsewhere ? "+error" : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="hadScanElsewhere"
                                                            component={InputYesNo}
                                                            label="Have you had a mammogram elsewhere?"
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "hadScanElsewhere",
                                                                    e.target.value === "true"
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {String(values.hadScanElsewhere) === "true" && (
                                                        <fieldset className="u-pad-none u-marg-top-small">
                                                            <div
                                                                className={`c-field ${
                                                                    errors.previousElseWhereMammogram
                                                                        ? "+error"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <CIDLabel
                                                                    required
                                                                    className="u-marg-none"
                                                                >
                                                                    Approx date of last mammogram
                                                                </CIDLabel>
                                                                <DatePickerField
                                                                    name="previousElseWhereMammogram"
                                                                    className="+text"
                                                                    style={{ display: "block" }}
                                                                    placeholder={"2019-12-31"}
                                                                    defaultValue={
                                                                        values.previousElseWhereMammogram
                                                                            ? values.previousElseWhereMammogram
                                                                                  .toString()
                                                                                  .split("T")[0]
                                                                            : undefined
                                                                    }
                                                                    value={
                                                                        values.previousElseWhereMammogram
                                                                            ? values.previousElseWhereMammogram
                                                                                  .toString()
                                                                                  .split("T")[0]
                                                                            : undefined
                                                                    }
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "previousElseWhereMammogram",
                                                                            e.target.value
                                                                        );
                                                                        validatePreviousScan(
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                />
                                                                <ErrorMessage
                                                                    component="span"
                                                                    name={
                                                                        "previousElseWhereMammogram"
                                                                    }
                                                                    className="c-field__feedback"
                                                                />
                                                            </div>
                                                        </fieldset>
                                                    )}
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.pregnantOrBreastfeeding
                                                                ? "+error"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="pregnantOrBreastfeeding"
                                                            component={InputYesNo}
                                                            label="Is there any possibility that you are pregnant or breastfeeding or have been within last 6 months?"
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "pregnantOrBreastfeeding",
                                                                    e.target.value === "true"
                                                                );
                                                                validatePregnantOrBreastFeeding(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.existingConcerns ? "+error" : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="existingConcerns"
                                                            component={InputYesNo}
                                                            label="Have you got any current breast problems or
                                                            concerns?"
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "existingConcerns",
                                                                    e.target.value === "true"
                                                                );
                                                                validateConcerns(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`c-field u-marg-bottom ${
                                                            errors.northernIrelandResident
                                                                ? "+error"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Field
                                                            name="northernIrelandResident"
                                                            component={InputYesNo}
                                                            label="                                               Are you a full time resident of Northern Ireland
                                                            &amp; will you be available for 4 weeks after
                                                            mammogram in case further tests are needed?"
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "northernIrelandResident",
                                                                    e.target.value === "true"
                                                                );
                                                                validateNIResident(e.target.value);
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l-app__item +end">
                                <div className="u-pad u-fill-white u-border-top">
                                    <div className="u-flex c-footer">
                                        <button
                                            type="button"
                                            className="c-button +quiet u-marg-right u-marg-bottom-small"
                                            onClick={() => {
                                                store.setEnteringPersonalDetails(true);
                                            }}
                                        >
                                            <svg className="c-icon u-font-xl u-marg-right-small">
                                                <use href="/symbol-defs.svg#icon-arrow-left" />
                                            </svg>
                                            Back
                                        </button>

                                        <button
                                            type="submit"
                                            className="c-button u-fill-1 +primary u-marg-bottom-small"
                                            onClick={submitForm}
                                            disabled={isSubmitting}
                                        >
                                            Book Appointment
                                            <svg className="c-icon u-font-xl u-marg-left-small">
                                                <use href="/symbol-defs.svg#icon-check" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
});

export default BreastScreeningInformation;
