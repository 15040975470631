import { computed, makeObservable, observable } from "mobx";
import { inject, injectable } from "inversify";
import { CORE_TYPES, LoadableStore, StorageService } from "@gcdtech/acorn-react-core";
import { Appointment } from "common";
import { BOOKING_TYPES } from "../ioc/BookingTypes";

@injectable()
export default class ProfileStore extends LoadableStore {
    @inject(CORE_TYPES.StorageService) private storageService: StorageService;
    @inject(BOOKING_TYPES.TokenStorageKey) private tokenStorageKey: string;

    loggedInUserName?: string;

    token?: string = "";

    /**
     * A list of upcoming appointments for the user
     */
    upcomingAppointments: Appointment[];

    constructor() {
        super();

        makeObservable(this, {
            loggedInUserName: observable,

            token: observable,

            upcomingAppointments: observable,

            loggedIn: computed,
        });
    }

    public get loggedIn() {
        return this.token || this.storageService.getItem(this.tokenStorageKey) ? true : false;
    }
}
