import React from "react";

import { observer } from "mobx-react-lite";
import BookingStore from "booking/store/BookingStore";
import { HealthCheckBookingData } from "common";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { InputYesNo } from "core/components/generic/InputYesNo";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";

const HealthCheckInformation = observer(() => {
    const store = BookingStore.get();
    const bookingData = store.bookingData as HealthCheckBookingData;

    const onSubmit = async (values: Partial<HealthCheckBookingData>) => {
        await SubmitBookingUseCase.create().execute({ ...bookingData, ...values });
    };

    return (
        <Formik
            initialValues={{
                testConsent: bookingData.testConsent,
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object({
                testConsent: yup.bool().oneOf([true], 'You must agree to proceed'),
            })}
            validateOnChange={true}
        >
            {({ submitForm, setFieldValue, isSubmitting }) => {
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
                        <div className="l-app__item +middle">
                            <div className="u-height-100pc u-pad">
                                <div className="u-flex u-align-items-center u-justify-content-center">
                                    <div className="o-wrap u-width-login">
                                        <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                                            <div className="u-pad-large">
                                                <p className="u-font-bold u-font-xxl u-marg-bottom">
                                                    Data Protection
                                                </p>
                                                <p className="u-marg-bottom">
                                                    By attending an Action Cancer health check, you
                                                    are consenting to our staff carrying out these
                                                    tests. If any of your results are outside the
                                                    recommended ranges we will automatically refer
                                                    you to your GP and send them the results of your
                                                    health check.
                                                </p>

                                                <p className="u-marg-bottom">
                                                    Action Cancer promises to respect any personal
                                                    data you share with us and keep it safe.
                                                </p>

                                                <p className="u-marg-bottom">
                                                    Anonymised health check data may be shared with
                                                    third parties, such as research facilities,
                                                    event organisers or funders etc. It will not be
                                                    available in a form that identifies individuals.
                                                </p>

                                                {/* TODO: Change to single checkbox */}
                                                <Field
                                                    name="testConsent"
                                                    component={InputYesNo}
                                                    label="I understand how AC will process my information."
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "testConsent",
                                                            e.target.value === "true"
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="l-app__item +end">
                            <div className="u-pad u-fill-white u-border-top">
                                <div className="u-flex c-footer">
                                    <button
                                        type="button"
                                        className="c-button +quiet u-marg-right u-marg-bottom-small"
                                        onClick={() => {
                                            store.setEnteringPersonalDetails(true);
                                        }}
                                    >
                                        <svg className="c-icon u-font-xl u-marg-right-small">
                                            <use href="/symbol-defs.svg#icon-arrow-left" />
                                        </svg>
                                        Back
                                    </button>

                                    <button
                                        type="submit"
                                        className="c-button u-fill-1 +primary u-marg-bottom-small"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Book Appointment
                                        <svg className="c-icon u-font-xl u-marg-left-small">
                                            <use href="/symbol-defs.svg#icon-check" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
});

export default HealthCheckInformation;
