import React from "react";

import { observer } from "mobx-react-lite";

const InvitationExpired = observer(() => {
    return (
        <div onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
            <div className="l-app__item +middle">
                <div className="u-height-100pc u-pad">
                    <div className="u-flex u-align-items-center u-justify-content-center u-height-100pc">
                        <div className="o-wrap u-width-login">
                            <div className="u-border u-align-center u-rounded u-fill-white u-shadow-ambient">
                                <div className="u-pad-large">
                                    <p className="u-font-bold u-font-xxl">Invitation Expired</p>
                                    <p className="u-marg-bottom">
                                        Sorry your invitation link has expired.
                                    </p>
                                    <p className="u-marg-bottom">
                                        Please ring Action Cancer on{" "}
                                        <a href="tel:02890803344">
                                            <u>028 9080 3344</u>
                                        </a>{" "}
                                        to discuss your Skin Cancer Early Detection appointment
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default InvitationExpired;
