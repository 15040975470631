import { injectable, inject } from "inversify";
import BookingService from "./BookingService";
import dayjs, { Dayjs } from "dayjs";
import {
    ApolloClient,
    gql,
    NormalizedCacheObject,
    InMemoryCache,
    createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import {
    TimeSlot,
    Doctor,
    BookingData,
    ContactDetails,
    AuthenticationType,
    Appointment,
    AppointmentLocation,
    Invitation,
} from "common";
import {
    CounsellingBookingData,
    HealthCheckBookingData,
    BreastScreeningBookingData,
    ComplementaryTherapyBookingData,
} from "common";

@injectable()
export default class ApiBookingService implements BookingService {
    protected graphQlAdapter: ApolloClient<NormalizedCacheObject>;

    @inject(BOOKING_TYPES.ApiUri)
    graphQlApiUri: string;

    @inject(BOOKING_TYPES.TokenStorageKey) private tokenStorageKey: string;

    getClient() {
        const httpLink = createHttpLink({
            uri: this.graphQlApiUri,
        });

        const authLink = setContext((_, { headers }) => {
            const token = localStorage.getItem(this.tokenStorageKey);
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : "",
                },
            };
        });

        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
        });
    }

    async initiateLoginWithEmail(email: string): Promise<boolean> {
        const result = await this.getClient().query<{ loginWithEmail: boolean }>({
            query: gql`
                query {
                    loginWithEmail(email: "${email}") 
                }
            `,
        });

        return result.data.loginWithEmail;
    }

    async initiateLoginWithPhone(phone: string): Promise<boolean> {
        const result = await this.getClient().query<{ loginWithPhone: boolean }>({
            query: gql`
                query {
                    loginWithPhone(phone: "${phone}")
                }
            `,
        });

        return result.data.loginWithPhone;
    }

    async loginWithCode(
        code: string,
        identifier: string,
        authenticatingBy: AuthenticationType
    ): Promise<string> {
        const result = await this.getClient().query<{ verifyCode: string }>({
            query: gql`
                query {
                    verifyCode(code: "${code}", identifier: "${identifier}", type: "${authenticatingBy}") 
                }
            `,
        });

        return result.data.verifyCode;
    }

    async registerAccount(contactDetails): Promise<ContactDetails & { authToken }> {
        if (contactDetails.gp) {
            delete contactDetails.gp;
        }

        const result = await this.getClient().mutate({
            mutation: gql`
                mutation register($inputs: UserInput!) {
                    register(registerData: $inputs) {
                        title
                        firstName
                        surname
                        maidenName
                        preferredName
                        dob
                        gender
                        email
                        telephone
                        address
                        address2
                        town
                        country
                        county {
                            id
                        }
                        postCode
                        mobile
                        trust
                        gp {
                            forename: gp_forename
                            surname: gp_name
                            practice {
                                addressLine1: address1
                                postcode
                            }
                            id
                        }
                        nhsNumber
                        authToken
                        marketingEmail
                        marketingSMS
                        marketingPhone
                        marketingPost
                        aboutUs
                    }
                }
            `,
            variables: {
                inputs: contactDetails,
            },
        });

        return result.data.register;
    }

    async updateAccount(contactDetails: ContactDetails): Promise<ContactDetails> {
        if (contactDetails.gp) {
            delete contactDetails.gp;
        }

        const result = await this.getClient().mutate({
            mutation: gql`
                mutation updateAccount($inputs: UserInput!) {
                    updateAccount(updateData: $inputs) {
                        title
                        firstName
                        surname
                        maidenName
                        preferredName
                        dob
                        gender
                        email
                        telephone
                        address
                        address2
                        town
                        country
                        county {
                            id
                        }
                        postCode
                        mobile
                        trust
                        gp {
                            forename: gp_forename
                            surname: gp_name
                            practice {
                                addressLine1: address1
                                postcode
                            }
                            id
                        }
                        nhsNumber
                        marketingEmail
                        marketingSMS
                        marketingPhone
                        marketingPost
                        aboutUs
                    }
                }
            `,
            variables: {
                inputs: contactDetails,
            },
        });

        return result.data.updateAccount;
    }

    async getAuthenticatedUserContactDetails(): Promise<Partial<ContactDetails>> {
        const result = await this.getClient().query<{ me: ContactDetails }>({
            query: gql`
                query {
                    me {
                        title
                        firstName
                        surname
                        maidenName
                        preferredName
                        dob
                        gender
                        email
                        telephone
                        address
                        address2
                        town
                        country
                        county {
                            id
                        }
                        postCode
                        mobile
                        trust
                        gp {
                            forename: gp_forename
                            surname: gp_name
                            practice {
                                addressLine1: address1
                                postcode
                            }
                            id
                        }
                        nhsNumber
                        marketingEmail
                        marketingSMS
                        marketingPhone
                        marketingPost
                        aboutUs
                    }
                }
            `,
        });

        return result.data.me;
    }

    async getDatesWithTimeSlots(
        appointmentTypeId: number,
        eventCode: string,
        clientId: string,
        locationIds: number[]
    ): Promise<Dayjs[]> {
        const result = await this.getClient().query<{ dates: Date[] }>({
            query: gql`
                query dates(
                    $appointmentType: Int
                    $eventCode: String
                    $lockingClient: String
                    $appointmentLocationIds: [Int!]
                ) {
                    dates(
                        appointmentType: $appointmentType
                        eventCode: $eventCode
                        appointmentLocationIds: $appointmentLocationIds
                        lockingClient: $lockingClient
                    )
                }
            `,
            variables: {
                appointmentType: appointmentTypeId,
                eventCode,
                appointmentLocationIds: locationIds,
                lockingClient: clientId,
            },
        });

        return result.data.dates.map((date) => dayjs(date));
    }

    async getTimeSlots(
        appointmentTypeId: number,
        eventCode: string,
        date: Dayjs,
        clientId: string,
        locationIds: number[]
    ): Promise<TimeSlot[]> {
        const result = await this.getClient().query<{ timeslots: TimeSlot[] }>({
            query: gql`
                query timeslots(
                    $appointmentType: Int
                    $eventCode: String
                    $date: DateTime!
                    $appointmentLocationIds: [Int!]
                    $lockingClient: String
                ) {
                    timeslots(
                        appointmentType: $appointmentType
                        eventCode: $eventCode
                        date: $date
                        appointmentLocationIds: $appointmentLocationIds
                        lockingClient: $lockingClient
                    ) {
                        id
                        date
                        address
                        locationDescription
                        location {
                            location
                        }
                        duration
                    }
                }
            `,
            variables: {
                appointmentType: appointmentTypeId,
                eventCode,
                date: date.toDate(),
                appointmentLocationIds: locationIds,
                lockingClient: clientId,
            },
        });

        return result.data.timeslots.map((timeslot) => ({
            ...timeslot,
            date: dayjs(timeslot.date),
        }));
    }

    async lockAppointment(timeslotId: string, clientId: string): Promise<TimeSlot> {
        const result = await this.getClient().mutate({
            mutation: gql`
                mutation lock($inputs: Float!, $clientId: String!) {
                    lock(timeslotId: $inputs, clientId: $clientId) {
                        id
                        date
                        lockedUntil
                        available
                        location {
                            location
                        }
                        subLocation {
                            address
                            town
                            postcode
                        }
                    }
                }
            `,
            variables: {
                inputs: timeslotId,
                clientId,
            },
        });

        return {
            ...result.data.lock,
            lockedUntil: dayjs(result.data.lock.lockedUntil),
        };
    }

    async submitBooking(
        bookingData: BookingData &
            CounsellingBookingData &
            HealthCheckBookingData &
            BreastScreeningBookingData &
            ComplementaryTherapyBookingData,
        timeslotId: number,
        appointmentTypeId: number
    ): Promise<boolean> {
        const inputs: Omit<BookingData, "contactDetails" | "contactPreference"> & {
            timeSlotID: number;
            appointmentTypeId: number;
        } = {
            ...bookingData,
            timeSlotID: timeslotId,
            appointmentTypeId: appointmentTypeId,
        };
        if (inputs["contactDetails"]) {
            delete inputs["contactDetails"];
        }

        if (inputs["contactPreference"]) {
            delete inputs["contactPreference"];
        }

        await this.getClient().mutate<{ bookingData: BookingData }>({
            mutation: gql`
                mutation createAppointmentBooking($inputs: AppointmentInput!) {
                    createAppointmentBooking(appointmentDetails: $inputs) {
                        id
                    }
                }
            `,
            variables: {
                inputs,
            },
        });

        return true;
    }

    async getDoctors(query: string): Promise<Doctor[]> {
        const result = await this.getClient().query<{ gps: Doctor[] }>({
            query: gql`
                query {
                    gps(searchCriteria: "${query}") {
                        id
                        surname: gp_name
                        forename: gp_forename
                        practice {
                            name: practice_name
                            addressLine1: address1
                            addressLine2: address2
                            addressLine3: address3
                            postcode
                        }
                    }
                }
            `,
        });
        return result.data.gps;
    }

    async getUpcomingAppointments(): Promise<Appointment[]> {
        const result = await this.getClient().query<{ upcomingAppointments: Appointment[] }>({
            query: gql`
                query {
                    upcomingAppointments {
                        id
                        timeslot {
                            id
                            date
                            location {
                                location
                            }
                            address
                            locationDescription
                            reference
                        }
                        appointmentTypeId
                        notes
                    }
                }
            `,
        });
        return result.data.upcomingAppointments.map((upcomingAppointment) => ({
            ...upcomingAppointment,
            timeslot: {
                ...upcomingAppointment.timeslot,
                date: dayjs(upcomingAppointment.timeslot.date),
            },
        }));
    }

    async getLocationsWithTimeSlots(
        appointmentTypeId: number,
        eventCode: string
    ): Promise<AppointmentLocation[]> {
        const result = await this.getClient().query<{
            locations: AppointmentLocation[];
        }>({
            query: gql`
                query {
                    locations(appointmentType: ${appointmentTypeId}, eventCode: "${eventCode}"){
                        id
                        location
                    }
                }
            `,
        });
        return result.data.locations;
    }

    async cancelAppointment(appointment: Appointment, isRescheduled?: boolean): Promise<void> {
        await this.getClient().mutate({
            mutation: gql`
                mutation cancelAppointment($appointmentId: Float!, $isRescheduled: Boolean) {
                    cancelAppointment(appointmentId: $appointmentId, isRescheduled: $isRescheduled)
                }
            `,
            variables: {
                appointmentId: appointment.id,
                isRescheduled,
            },
        });
    }

    async getInvitationDetails(token: string) {
        const result = await this.getClient().query<{
            getInvitationDetails: Invitation;
        }>({
            query: gql`
            query {
                getInvitationDetails(token: "${token}"){
                    expired
                }
            }
        `,
        });
        return result.data.getInvitationDetails;
    }

    async getPreviousAppointmentDate(appointmentTypeId?: number): Promise<string> {
        const result = await this.getClient().query<{ previousAppointmentDate: string }>({
            query: gql`
                query {
                    previousAppointmentDate(appointmentType: ${appointmentTypeId})
                }
            `
        });

        return result.data.previousAppointmentDate;
    }
}
