import { AcornUseCase } from "@gcdtech/acorn-react-core";
import { BOOKING_TYPES } from "booking/ioc/BookingTypes";
import BookingService from "booking/services/BookingService";
import BookingStore from "booking/store/BookingStore";
import ProfileStore from "booking/store/ProfileStore";
import { ContactPreference, ContactDetails, BookingData } from "common";
import { inject } from "inversify";
import LogoutUseCase from "./authentication/LogoutUseCase";

export default class InitialiseBookingUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) service: BookingService;

    *onExecute(clientId: string = "") {
        const bookingStore = BookingStore.get();
        const contactDetails = new ContactDetails();
        const contactPreference = new ContactPreference();
        bookingStore.bookingData = new BookingData();
        bookingStore.bookingData.contactDetails = contactDetails;
        bookingStore.bookingData.contactPreference = contactPreference;

        if (clientId !== "") {
            bookingStore.clientId = clientId;
        }

        if (ProfileStore.get().loggedIn) {
            // Check that our login is valid. We just re use an existing service method
            // to get teh profile details.
            try {
                yield this.service.getAuthenticatedUserContactDetails();
            } catch (e) {
                // User token must now be invalid.
                LogoutUseCase.create().execute();
                yield;
            }
        }
    }
}
