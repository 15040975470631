import { action, computed, makeObservable, observable } from "mobx";
import { inject, injectable } from "inversify";
import {
    ApiAdapter,
    asyncAction,
    CORE_TYPES,
    LoadableStore,
    StorageService,
} from "@gcdtech/acorn-react-core";
import {
    TimeSlot,
    Doctor,
    BookingData,
    ContactDetails,
    AuthenticationType,
    appointmentTypes,
    Appointment,
} from "common";
import BookingService from "../services/BookingService";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import { LoginInputs } from "../components/Login";
import TimeSlotsStore from "./TimeSlotStore";
import ProfileStore from "./ProfileStore";
import { getLocationString } from "../../core/utils/LocationUtils";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";

export enum BookingStep {
    Landing = "Landing",
    BookingWizard = "BookingWizard",
    HomeScreen = "HomeScreen",
    InvitationExpired = "InvitationExpired",
}

export enum HomeScreenView {
    Login = "Login",
    Register = "Register",
    VerifyCode = "VerifyCode",
    Profile = "Profile",
}

export enum BookingWizardStep {
    SelectDate = "Select Date",
    Login = "Login",
    MyDetails = "My Details",
    RequiredInformation = "Required Information",
    AppointmentConfirmed = "Appointment Confirmed",
}

@injectable()
export default class BookingStore extends LoadableStore {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;
    @inject(CORE_TYPES.StorageService) private storageService: StorageService;
    @inject(BOOKING_TYPES.TokenStorageKey) private tokenStorageKey: string;
    @inject(CORE_TYPES.ApiAdapter) private apiAdapter: ApiAdapter;

    /**
     * The client Id is a made up identifier that is used to understand who has locked
     * a slot in the back end. This allows a user to reselect a slot that they locked
     * previously.
     */
    clientId: string = "";
    authenticatingBy: AuthenticationType = AuthenticationType.None;
    enteringPersonalDetails: boolean = false;
    appointmentDetailsConfirmed: boolean;
    loggingIn: boolean;
    loggedIn: boolean = false;
    loginCredential?: string;
    loginErrorMessage?: string;
    verifyErrorMessage?: string;
    personalDetailsErrorMessage?: string;
    submissionErrorMessage?: string;

    // Temporarily keeps track of newly registered login details to auto stuff
    // the login form for convienience.
    registeredUserLoginInputs?: LoginInputs;

    initiateLoginDisabled: boolean;
    data: TimeSlot[];

    timeSlotStore: TimeSlotsStore;
    profileStore: ProfileStore;
    appointmentTypes;

    /**
     * The appointment slot selected by the user as an outcome from the calendar step
     */
    selectedTimeSlot?: TimeSlot;

    /**
     * A list of doctors for user querying
     */
    doctors: Doctor[];

    /**
     * The details of the appointment depending on service
     */
    bookingData: BookingData;

    /**
     * Only set to true once the appointment has been confirmed
     */
    confirmedAppointment: boolean;

    invitationToken: string;

    invitationExpired: boolean;
    /**
     * The appointment being rescheduled, to be cancelled
     * on confirmation of a successful booking through
     * the reschedule feature
     */
    appointmentToReschedule?: Appointment;

    previousAppointmentDate?: string;

    constructor() {
        super();

        makeObservable(this, {
            step: computed,
            wizardStep: computed,
            authenticatingBy: observable,
            enteringPersonalDetails: observable,
            loggingIn: observable,
            loggedIn: observable,
            appointmentDetailsConfirmed: observable,
            loginCredential: observable,
            selectedTimeSlot: observable,

            loginErrorMessage: observable,
            verifyErrorMessage: observable,
            personalDetailsErrorMessage: observable,
            registeredUserLoginInputs: observable,

            initiateLoginDisabled: observable,
            data: observable,

            appointmentToReschedule: observable,
            invitationToken: observable,
            invitationExpired: observable,

            setEnteringPersonalDetails: action,
            setLoggedIn: action,
            configureAuthToken: action,
            tryAnotherPhoneOrEmail: action,
            disableInitiateLogin: action,
            returnToLanding: action,
            setAppointmentToReschedule: action,

            getInvitationDetails: action,

            appointmentLocation: computed,

            getPreviousAppointmentDate: action,
        });

        this.clientId = Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 8);

        this.timeSlotStore = TimeSlotsStore.get();
        this.profileStore = ProfileStore.get();
        this.appointmentTypes = appointmentTypes;
    }

    public get step() {
        const noAppointments =
            this.timeSlotStore.datesWithAppointments &&
            this.timeSlotStore.datesWithAppointments.length > 0;

        // When a filter is applied we don't want to go back to the landing
        const filterApplied = this.timeSlotStore.selectedDate;

        if (this.invitationExpired) {
            return BookingStep.InvitationExpired;
        }

        if (this.timeSlotStore.selectedAppointmentTypeId && (noAppointments || filterApplied)) {
            if (
                !this.profileStore.loggedIn &&
                this.timeSlotStore.selectedAppointmentTypeId ===
                    this.appointmentTypes.stepIntoAction
            ) {
                return BookingStep.Landing;
            }
            return BookingStep.BookingWizard;
        }

        return BookingStep.Landing;
    }

    public get homeScreenView() {
        if (this.profileStore.token) {
            return HomeScreenView.Profile;
        }
        if (!this.profileStore.loggedIn) {
            if (this.loginCredential) {
                return HomeScreenView.VerifyCode;
            }
            if (!this.enteringPersonalDetails) {
                return HomeScreenView.Login;
            }
            if (this.enteringPersonalDetails) {
                return HomeScreenView.Register;
            }
        }
        return HomeScreenView.Profile;
    }

    public get wizardStep() {
        if (
            (!this.profileStore.loggedIn &&
                this.selectedTimeSlot &&
                !this.enteringPersonalDetails) ||
            this.loggingIn
        ) {
            return BookingWizardStep.Login;
        }

        if (this.invitationExpired) {
            return BookingStep.InvitationExpired;
        }

        if (!this.selectedTimeSlot) {
            return BookingWizardStep.SelectDate;
        }
        if (this.appointmentDetailsConfirmed) {
            return BookingWizardStep.AppointmentConfirmed;
        }
        if ((this.profileStore.loggedIn || this.loggedIn) && !this.enteringPersonalDetails) {
            if (this.timeSlotStore.selectedAppointmentTypeId !== appointmentTypes.stepIntoAction) {
                return BookingWizardStep.RequiredInformation;
            }
        }

        return BookingWizardStep.MyDetails;
    }

    public get appointmentLocation() {
        let location = "";
        if (this.selectedTimeSlot) {
            location = getLocationString(this.selectedTimeSlot);
        }

        return location;
    }

    public setEnteringPersonalDetails(registering: boolean) {
        this.enteringPersonalDetails = registering;
    }

    public setAppointmentDetailsConfirmed(confirmed: boolean) {
        this.appointmentDetailsConfirmed = confirmed;
    }

    public tryAnotherPhoneOrEmail() {
        this.loginCredential = undefined;
    }

    public disableInitiateLogin() {
        this.initiateLoginDisabled = true;
        setTimeout(() => (this.initiateLoginDisabled = false), 30000);
    }

    public configureAuthToken = function (this: BookingStore, authToken: string) {
        this.storageService.saveItem(this.tokenStorageKey, authToken);

        const tokenFromStorage = this.storageService.getItem(this.tokenStorageKey);

        if (tokenFromStorage === null) {
            return;
        }

        this.apiAdapter.setAuthenticationToken(tokenFromStorage);

        this.profileStore.token = authToken;
    };

    public updateAndSetContactDetails = asyncAction(function* (
        this: BookingStore,
        contactDetails: ContactDetails
    ) {
        try {
            // Capture or update the contact details for user
            if (this.profileStore.loggedIn) {
                const {
                    marketingEmail,
                    marketingPhone,
                    marketingSMS,
                    marketingPost,
                    aboutUs,
                    ...details
                } = yield this.service.updateAccount(contactDetails);
                this.bookingData.contactPreference.email = marketingEmail;
                this.bookingData.contactPreference.phone = marketingPhone;
                this.bookingData.contactPreference.sms = marketingSMS;
                this.bookingData.contactPreference.post = marketingPost;
                this.bookingData.contactPreference.howDidYouHearAboutUs = aboutUs;

                this.bookingData.contactDetails = details;
            } else {
                const { authToken, ...rest } = yield this.service.registerAccount(contactDetails);

                const {
                    marketingEmail,
                    marketingPhone,
                    marketingSMS,
                    marketingPost,
                    aboutUs,
                    ...details
                } = rest;

                this.bookingData.contactPreference.email = marketingEmail;
                this.bookingData.contactPreference.phone = marketingPhone;
                this.bookingData.contactPreference.sms = marketingSMS;
                this.bookingData.contactPreference.post = marketingPost;
                this.bookingData.contactPreference.howDidYouHearAboutUs = aboutUs;
                this.bookingData.contactDetails = details;

                this.configureAuthToken(authToken);

                if (this.selectedTimeSlot) {
                    if (
                        this.profileStore.loggedIn &&
                        (this.timeSlotStore.selectedAppointmentTypeId ===
                            appointmentTypes.stepIntoAction ||
                            this.timeSlotStore.selectedAppointmentTypeId ===
                                appointmentTypes.clinicalAssessment)
                    ) {
                        const bookingData = this.bookingData as BookingData;
                        SubmitBookingUseCase.create().execute({ ...bookingData });
                    }
                }
            }

            this.personalDetailsErrorMessage = undefined;

            // Keep track of the entered details, just to auto fill the login form
            this.registeredUserLoginInputs = {
                email: contactDetails.email,
                phone: contactDetails.mobile,
            };

            this.setEnteringPersonalDetails(false);
            this.setLoggedIn(this.profileStore.loggedIn);
        } catch (error) {
            this.personalDetailsErrorMessage =
                error?.response?.data?.message || error.message || error;
            this.registeredUserLoginInputs = undefined;
        }
    });

    public populateMyDetails = asyncAction(function* (this: BookingStore) {
        try {
            const {
                marketingEmail,
                marketingPhone,
                marketingSMS,
                marketingPost,
                aboutUs,
                ...details
            } = yield this.service.getAuthenticatedUserContactDetails();

            this.bookingData.contactPreference.email = marketingEmail;
            this.bookingData.contactPreference.phone = marketingPhone;
            this.bookingData.contactPreference.sms = marketingSMS;
            this.bookingData.contactPreference.post = marketingPost;
            this.bookingData.contactPreference.howDidYouHearAboutUs = aboutUs;

            this.bookingData.contactDetails = details;
            this.setEnteringPersonalDetails(true);
        } catch (e) {
            console.log("removing token");
            this.storageService.removeItem(this.tokenStorageKey);
            console.log("token now: ", this.storageService.getItem(this.tokenStorageKey));
            this.setEnteringPersonalDetails(false);
        }
    });

    public getInvitationDetails = asyncAction(function* (this: BookingStore) {
        try {
            const { expired } = yield this.service.getInvitationDetails(this.invitationToken);
            this.invitationExpired = expired;
        } catch (e) {
            this.invitationExpired = true;
        }
    });

    public getPreviousAppointmentDate = asyncAction(function* (this: BookingStore, appointmentTypeId?: number) {
        try {
            this.previousAppointmentDate = yield this.service.getPreviousAppointmentDate(appointmentTypeId);
        } catch (e) {
            this.previousAppointmentDate = "";
        }
    });

    public setLoggingIn(loggingIn: boolean) {
        this.loggingIn = loggingIn;
    }

    public setLoggedIn(loggedIn: boolean) {
        this.loggedIn = loggedIn;
    }

    public returnToLanding() {
        this.timeSlotStore.selectedAppointmentTypeId = undefined;
        this.timeSlotStore.datesWithAppointments = [];
    }

    public async returnToSelectAppointment() {
        this.selectedTimeSlot = undefined;
    }

    public setAppointmentToReschedule(appointment?: Appointment) {
        this.appointmentToReschedule = appointment;
    }

    public setInvitationToken(token: string) {
        this.invitationToken = token;
    }
}
