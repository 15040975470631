import React from "react";
import CIDErrorModal from "core/components/generic/CIDErrorModal";
import { appointmentTypes } from "common";

type AgeValidationErrorProps = {
    open: boolean;
    onClose: () => void;
    appointmentTypeId?: number;
};

export const AgeValidationError = (props: AgeValidationErrorProps) => {
    const { appointmentTypeId, open, onClose } = props;

    if (!appointmentTypeId) {
        return null;
    }

    return (
        <CIDErrorModal
            open={open}
            onClose={onClose}
            heading={"Sorry you are not eligible for screening at Action Cancer"}
        >
            {appointmentTypeId === appointmentTypes.breastScreening && (
                <>
                    <p className="u-marg-bottom u-bold">
                        Please select an appointment date when you are within the eligible age range
                        (40-49 or 70+)
                    </p>
                    <p className="u-marg-bottom u-bold">
                        Who is eligible for breast screening at Action Cancer?
                    </p>
                    <p>
                        Action Cancer offers Breast Screening for women without symptoms, who have
                        not had a mammogram elsewhere within two years and are aged 40-49 or 70+.
                    </p>
                    <p className="u-marg-bottom u-font-bold u-font-medium u-color-navy">
                        What do I do if I am aged 50-70?
                    </p>
                    <p className="u-marg-bottom">
                        In Northern Ireland, all women registered with a GP between the ages of 50
                        and 70 are called every three years for a mammogram with the NHS Breast
                        Screening Programme. This is organised via your GP. For more information
                        contact your GP or local breast screening unit:
                    </p>
                    <ul className="u-marg-bottom u-bullet-list">
                        <li>
                            Belfast Health and Social Care Trust{" "}
                            <a href="tel:02890333700">
                                <u>028 9033 3700</u>
                            </a>{" "}
                        </li>
                        <li>
                            Northern Health and Social Care Trust{" "}
                            <a href="tel:02894424425">
                                <u>028 9442 4425</u>
                            </a>{" "}
                        </li>
                        <li>
                            Southern Health and Social Care Trust{" "}
                            <a href="tel:02838347083">
                                <u>028 3834 7083</u>
                            </a>{" "}
                        </li>
                        <li>
                            Western Health and Social Care Trust{" "}
                            <a href="tel:02871611443">
                                <u>028 7161 1443</u>
                            </a>{" "}
                        </li>
                    </ul>
                    <p className="u-marg-bottom u-bold">
                        Why are screening mammograms not offered to younger women?
                    </p>
                    <p>
                        Screening mammography is not offered to women under 40 as there is no
                        evidence to show that it is beneficial in this age group. The younger you
                        are the denser the breast tissue, this means that it is very hard to pick up
                        abnormalities in the breast tissue and makes mammography often an
                        inappropriate examination for younger women.
                    </p>
                    <p>
                        However, women under 40 with breast concerns should see their GP. They can
                        make a referral to a specialist Breast Clinic where the woman can be
                        examined using the most appropriate test. Otherwise the best advice is to be
                        breast aware and when you turn 40 to book an appointment with Action Cancer.
                    </p>
                </>
            )}
            {appointmentTypeId === appointmentTypes.healthCheck && (
                <>
                    <p className="u-marg-bottom u-bold">
                        Who is eligible for a health check at Action Cancer?
                    </p>
                    <p>
                        Participants must be aged 18 or over to take part in these health checks
                    </p>
                </>
            )}
        </CIDErrorModal>
    );
};
