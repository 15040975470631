import React from "react";

import { observer } from "mobx-react-lite";
import BookingStore from "booking/store/BookingStore";
import { ComplementaryTherapyBookingData, TypeLabels } from "common";
import { Formik, Field, ErrorMessage } from "formik";
import { Label, Select } from "morse-react";
import * as yup from "yup";
import { InputYesNo } from "core/components/generic/InputYesNo";
import {
    SpecialRequirementsWarning,
    AttendedInTheLast6MonthsError,
    AlreadyReceivingTherapyError,
} from "./validation/TherapeuticServicesValidation";
import SubmitBookingUseCase from "booking/use-cases/SubmitBookingUseCase";

const ComplementaryTherapyInformation = observer(() => {
    const store = BookingStore.get();
    const bookingData = store.bookingData as ComplementaryTherapyBookingData;

    const onSubmit = async (values: Partial<ComplementaryTherapyBookingData>) => {
        await SubmitBookingUseCase.create().execute({ ...bookingData, ...values });
    };

    return (
        <Formik
            initialValues={{
                attendedWithinLast6Months: bookingData.attendedWithinLast6Months,
                receivingTherapyFromOtherOrganisation:
                    bookingData.receivingTherapyFromOtherOrganisation,
                specialRequirements: bookingData.specialRequirements,
                userTypeID: bookingData.userTypeID,
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object({
                attendedWithinLast6Months: yup
                    .bool()
                    .oneOf([false], "Not eligible for this service")
                    .required("Please choose Yes or No"),
                receivingTherapyFromOtherOrganisation: yup
                    .bool()
                    .oneOf([false], "Not eligible for this service")
                    .required("Please choose Yes or No"),
                specialRequirements: yup
                    .bool()
                    .oneOf([false], "Not eligible for this service")
                    .required("Please choose Yes or No"),
                userTypeID: yup.number().required("Please select an option"),
            })}
            validateOnChange={true}
        >
            {({ submitForm, handleChange, values, setFieldValue, isSubmitting, errors }) => {
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="l-app +y +gap-0">
                        <div className="l-app__item +middle">
                            <div className="u-height-100pc u-pad">
                                <div className="u-flex u-align-items-center u-justify-content-center">
                                    <div className="o-wrap u-width-login">
                                        <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                                            <div className="u-pad-large">
                                                <p className="u-font-bold u-font-xxl u-marg-bottom">
                                                    Screening questions
                                                </p>
                                                <>
                                                    {errors.receivingTherapyFromOtherOrganisation ===
                                                        "Not eligible for this service" && (
                                                        <AlreadyReceivingTherapyError />
                                                    )}
                                                    {errors.specialRequirements ===
                                                        "Not eligible for this service" && (
                                                        <SpecialRequirementsWarning />
                                                    )}
                                                    {errors.attendedWithinLast6Months ===
                                                        "Not eligible for this service" && (
                                                        <AttendedInTheLast6MonthsError />
                                                    )}
                                                </>
                                                <div>
                                                    <div className="u-marg-bottom">
                                                        <Field
                                                            name="attendedWithinLast6Months"
                                                            component={InputYesNo}
                                                            label="Have you attended Action Cancer for Complementary Therapy within the past 6 months?"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "attendedWithinLast6Months",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="u-marg-bottom">
                                                        <Field
                                                            name="receivingTherapyFromOtherOrganisation"
                                                            component={InputYesNo}
                                                            label="Are you currently receiving Complementary Therapy with another organisation?"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "receivingTherapyFromOtherOrganisation",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="u-marg-bottom">
                                                        <Field
                                                            name="specialRequirements"
                                                            component={InputYesNo}
                                                            label="Do you have any special requirements/disabilities that Action Cancer should be aware of:"
                                                            required
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "specialRequirements",
                                                                    e.target.value === "true"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="u-marg-bottom">
                                                        <Label className="u-marg-none">
                                                            Please tell us how you found out about
                                                            our service:
                                                        </Label>
                                                        <Select
                                                            initialOptions={Object.entries(
                                                                TypeLabels
                                                            ).map(([value, label]) => ({
                                                                label,
                                                                value,
                                                            }))}
                                                            fill
                                                            value={TypeLabels[values.userTypeID!]}
                                                            onChange={(selected?: string): void =>
                                                                setFieldValue(
                                                                    "userTypeID",
                                                                    Number(selected)
                                                                )
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            component="span"
                                                            name={"userTypeID"}
                                                            className="c-field__feedback"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="l-app__item +end">
                            <div className="u-pad u-fill-white u-border-top">
                                <div className="u-flex c-footer">
                                    <button
                                        type="button"
                                        className="c-button +quiet u-marg-right u-marg-bottom-small"
                                        onClick={() => {
                                            store.setEnteringPersonalDetails(true);
                                        }}
                                    >
                                        <svg className="c-icon u-font-xl u-marg-right-small">
                                            <use href="/symbol-defs.svg#icon-arrow-left" />
                                        </svg>
                                        Back
                                    </button>

                                    <button
                                        type="submit"
                                        className="c-button u-fill-1 +primary u-marg-bottom-small"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Book Appointment
                                        <svg className="c-icon u-font-xl u-marg-left-small">
                                            <use href="/symbol-defs.svg#icon-check" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
});

export default ComplementaryTherapyInformation;
