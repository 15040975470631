import FetchProfileUseCase from "booking/use-cases/FetchProfileUseCase";
import { Appointment } from "common";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { useEffect } from "react";
import ProfileStore from "../../store/ProfileStore";
import LogoutUseCase from "../../use-cases/authentication/LogoutUseCase";
import CancelAppointmentUseCase from "../../use-cases/CancelAppointmentUseCase";
import AppointmentItem from "./AppointmentItem";
import { Button } from "morse-react";
import ConfirmNewBooking from "./ConfirmNewBooking";

const Profile = observer(() => {
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmNewBooking, setConfirmNewBooking] = useState<boolean>(false);
    const profileStore = ProfileStore.get();

    const { loggedInUserName = "", upcomingAppointments = [] } = profileStore;

    const history = useHistory();

    const onCancelAppointment = async (appointment?: Appointment) => {
        if (appointment) {
            setLoading(true);
            try {
                await CancelAppointmentUseCase.create().execute(appointment);
            } catch (e) {}
            setLoading(false);
        }
    };

    const onLogOut = async () => {
        await LogoutUseCase.create().execute();
        history.push("/booking");
    };

    const onBookNew = () => {
        setConfirmNewBooking(true);
    };

    useEffect(() => {
        FetchProfileUseCase.create().execute();
    }, []);

    return (
        <div className="l-app +y">
            <div className="l-app__item +middle">
                {loading ? (
                    <div className="u-pad-top u-flex u-justify-content-center">
                        <div className="c-loader u-font-xxl"></div>
                    </div>
                ) : (
                    <div className="u-pad u-height-100pc">
                        <div className="l-wrap u-width-2">
                            <div className="u-marg-bottom">
                                <p className="c-heading +h1 u-font-bold">Hi {loggedInUserName}</p>
                                {upcomingAppointments && upcomingAppointments.length > 0 ? (
                                    <p className="u-font-bold u-font-medium u-marg-bottom-small u-color-navy">
                                        You have {upcomingAppointments.length} upcoming appointment
                                        {upcomingAppointments.length > 1 && "s"}:
                                    </p>
                                ) : (
                                    <p className="u-font-bold u-font-medium u-marg-bottom-small u-color-navy">
                                        You don't have any upcoming appointments
                                    </p>
                                )}
                            </div>
                            {upcomingAppointments.map((appointment: Appointment) => (
                                <AppointmentItem
                                    onCancel={onCancelAppointment}
                                    key={appointment.id}
                                    appointment={appointment}
                                />
                            ))}
                        </div>
                        <ConfirmNewBooking
                            open={confirmNewBooking}
                            loggedInUserName={loggedInUserName}
                            onDismissed={() => {
                                setConfirmNewBooking(false);
                            }}
                            onConfirm={() => {
                                setConfirmNewBooking(false);
                                history.push("/booking");
                            }}
                            onDeny={() => {
                                setConfirmNewBooking(false);
                                onLogOut();
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="l-app__item +end">
                <div className="u-pad u-fill-white u-border-top">
                    <div className="u-flex c-footer">
                        <Button
                            type="submit"
                            className="+primary u-width-100pc u-marg-right"
                            onClick={(e) => {
                                e.preventDefault();
                                onBookNew();
                            }}
                        >
                            <svg className="c-icon u-font-xl u-marg-right-small">
                                <use href="/symbol-defs.svg#icon-calendar-alt" />
                            </svg>
                            Book new
                        </Button>
                        <Button
                            type="button"
                            className="+primary +quiet u-width-100pc"
                            onClick={() => {
                                onLogOut();
                            }}
                        >
                            <svg className="c-icon u-font-xl u-marg-right-small">
                                <use href="/symbol-defs.svg#icon-sign-out-alt" />
                            </svg>
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Profile;
