import { Appointment, appointmentTypeLabels } from "common";
import { observer } from "mobx-react-lite";

import React from "react";
import RescheduleAppointmentUseCase from "../../use-cases/RescheduleAppointmentUseCase";
import TimeSlotsStore from "booking/store/TimeSlotStore";
import { useDecisionDialog, useOk } from "morse-react-dialogs";
import { ButtonColor } from "morse-react";
import { useHistory } from "react-router";
import BookingStore from "booking/store/BookingStore";
import { getLocationString } from "core/utils/LocationUtils";

type AppointmentItemProps = {
    appointment: Appointment;
    onCancel: (appointment?: Appointment) => void;
};

const AppointmentItem = observer((props: AppointmentItemProps) => {
    const history = useHistory();
    const { appointment, onCancel } = props;
    const { timeslot } = appointment;

    const { date } = timeslot;

    const noAppointmentsDialog = useOk({
        title: "No appointments",
        message:
            "Currently we have no availability, but please check back regularly for cancellations and extra availability.",
    });

    const onRescheduleAppointment = async () => {
        await RescheduleAppointmentUseCase.create().execute(appointment);

        if (TimeSlotsStore.get().datesWithAppointments?.length > 0) {
            history.push("/booking");
        } else {
            noAppointmentsDialog.show();
            BookingStore.get().setAppointmentToReschedule();
        }
    };

    const confirmCancelDialog = useDecisionDialog({
        title: "Cancel Appointment?",
        message: "Are you sure you want to cancel this appointment? This action cannot be undone.",
        choices: [
            { text: "Yes", value: appointment },
            { text: "No", value: undefined, buttonProps: { color: ButtonColor.Negative } },
        ],
        onChosen: onCancel,
    });
    const location = getLocationString(timeslot);

    return (
        <div className="u-border u-rounded u-pad u-shadow-ambient">
            <p className="u-font-medium u-block">{date.format("dddd D MMMM [at] h:mma")}</p>
            <p className="u-marg-bottom-small">
                <span className="u-color-navy">
                    {appointmentTypeLabels[appointment.appointmentTypeId]}
                </span>{" "}
                at{" "}
                <span
                    dangerouslySetInnerHTML={{
                        __html: `${location}`,
                    }}
                    className="u-color-navy"
                />
                <br />
                <a
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
                        location
                    )}`}
                    className="u-color-navy"
                >
                    <u>Get directions</u>
                </a>
            </p>
            <div className="u-flex u-justify-content-between">
                <button
                    className="u-color-1 u-flex u-align-items-center"
                    onClick={() => {
                        onRescheduleAppointment();
                    }}
                >
                    <svg className="c-icon u-marg-right-small">
                        <use href="/symbol-defs.svg#icon-calendar-alt" />
                    </svg>
                    Reschedule appointment
                </button>
                <button
                    className="u-flex u-align-items-center u-color-navy u-translucent"
                    onClick={() => confirmCancelDialog.show()}
                >
                    <svg className="c-icon u-marg-right-small">
                        <use href="/symbol-defs.svg#icon-trash" />
                    </svg>
                    Cancel
                </button>
            </div>
        </div>
    );
});

export default AppointmentItem;
