/* eslint @typescript-eslint/no-explicit-any: 0 */
import { debounce, reduce, isEqual } from "lodash";
import React, { Component } from "react";

interface SlyScrollerProps {
    onActive: (day) => void;
    disabled?: boolean;
    onInit?: (frame) => void;
    options: any;
}

class SlyScroller extends Component<SlyScrollerProps> {
    resizeHandler: any;
    frame: any;

    constructor(props: SlyScrollerProps) {
        super(props);

        // Reload the scroller when a resize occurs, but wait at least 100ms
        this.resizeHandler = debounce(() => this.frame.reload(), 100);
    }

    componentDidMount() {
        if (!this.frame) {
            // Create Sly instance
            //@ts-ignore
            this.frame = new window.Sly(this.refs.sly, this.props.options, {}).init();
            this.frame.on("active", (eventName, index) => {
                if (this.props.onActive) {
                    this.props.onActive(index);
                }
            });

            // This can be used to pass the Sly instance to the parent component
            if (this.props.onInit) {
                this.props.onInit(this.frame);
            }
        } else {
            this.frame.reload();
        }

        // Attach to the resize event
        window.addEventListener("resize", this.resizeHandler, true);
    }

    componentWillUnmount() {
        this.frame.off("active");
        this.frame.destroy();

        // Detach from the resize event
        window.removeEventListener("resize", this.resizeHandler);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // This computes the difference between incoming props and current props
        // and only allows the update if something has actually changed.

        // This shouldn't be required but I couldn't see what props was at fault

        const changedProps = reduce(
            this.props,
            function (result, value, key) {
                //@ts-ignore
                return isEqual(value, nextProps[key]) ? result : result.concat(key);
            },
            []
        );

        return changedProps.length !== 0;
    }

    componentDidUpdate(prevProps, prevState) {
        // After a props update
        if (this.frame) {
            // Update Sly options
            Object.assign(this.frame.options, this.props.options || {});

            // Reload Sly instance
            this.frame.reload();
            this.frame.activate(this.props.options.startAt);
        }
    }

    render() {
        return (
            <div ref="sly" className="frame c-calendar">
                <ul className="slidee">{this.props.children}</ul>
            </div>
        );
    }
}

export default SlyScroller;
