import { inject } from "inversify";
import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingStore from "../../store/BookingStore";
import BookingService from "../../services/BookingService";
import { BOOKING_TYPES } from "../../ioc/BookingTypes";
import { AuthenticationType } from "common";

export default class InitiateLoginWithEmailUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(email: string) {
        const bookingStore = BookingStore.get();

        bookingStore.authenticatingBy = AuthenticationType.Email;

        try {
            bookingStore.loginErrorMessage = undefined;
            const success = yield this.service.initiateLoginWithEmail(email);
            if (success) {
                bookingStore.loginCredential = email;
                bookingStore.disableInitiateLogin();
            } else {
                bookingStore.loginErrorMessage = "No account found.";
                bookingStore.loginCredential = undefined;
            }
        } catch (error) {
            bookingStore.loginErrorMessage =
                error?.response?.data?.message || error.message || error;
            bookingStore.loginCredential = undefined;
        }
    }
}
