import React, { useState } from "react";
import { Dayjs } from "dayjs";
import { appointmentTypes, TimeSlot } from "common";
import appointmentPlaceholder from "../../../images/placeholder.svg";
import { observer } from "mobx-react-lite";
import AppointmentInfo from "../../../booking/components/booking-wizard/steps/AppointmentInfo";

interface SlotsProps {
    slots: TimeSlot[];
    selectedDate: Dayjs;
    onSlotSelected: (slot: TimeSlot) => void;
    selectedSlot?: TimeSlot;
    service: appointmentTypes | undefined;
}

const Slots = observer((props: SlotsProps) => {
    const { slots, selectedDate, onSlotSelected, selectedSlot, service } = props;
    const [showAppointmentInfo, setShowAppointmentInfo] = useState<boolean>(false);

    let classNameAttribute = "c-radio c-module +stacked u-pad-left-right u-border-darken";

    const filteredByDate = slots.filter(
        (day) =>
            day.date.date() === selectedDate.date() &&
            day.date.month() === selectedDate.month() &&
            day.date.year() === selectedDate.year()
    );

    const sortedByStartTime = filteredByDate.sort((leftSide, rightStide): number => {
        if (leftSide.date < rightStide.date) return -1;
        if (leftSide.date > rightStide.date) return 1;
        return 0;
    });

    const addIsSelected = (slot: TimeSlot) => {
        if (selectedSlot && slot.id === selectedSlot.id) {
            classNameAttribute += " is-selected";
        }
    };

    const isNoAppointments = selectedDate && sortedByStartTime.length === 0;
    const appointmentsListClass = isNoAppointments ? "+right" : "c-checklist +right";

    return (
        <>
            {!selectedDate && (
                <div className="u-align-center u-flex u-align-items-center u-justify-content-center u-height-100pc">
                    <div>
                        <img src={appointmentPlaceholder} alt="Logo" style={{ width: "185px" }} />
                        <div className="u-width-1 u-color-navy">
                            <p className="u-font-bold u-font-large">Get started</p>
                            <p>Select a date to view available daily appointments</p>
                        </div>
                    </div>
                </div>
            )}
            {sortedByStartTime.length !== 0 && (
                <div className="u-flex u-marg-bottom-small u-justify-content-between u-align-items-center">
                    <p className="u-font-bold u-font-medium u-color-navy">
                        Available Appointments ({sortedByStartTime.length})
                    </p>
                    <button
                        className="c-button +text +small u-pad-none u-hide@m"
                        onClick={() => setShowAppointmentInfo(true)}
                    >
                        <u>Important info</u>
                    </button>
                </div>
            )}
            {showAppointmentInfo && (
                <AppointmentInfo
                    open={showAppointmentInfo}
                    onClose={() => setShowAppointmentInfo(false)}
                    service={service}
                />
            )}
            <div className={appointmentsListClass}>
                {sortedByStartTime.map((slot: TimeSlot) => {
                    return (
                        <label className={classNameAttribute} key={slot.id} htmlFor={slot.id}>
                            <input
                                type="radio"
                                name="radios"
                                value={slot.id}
                                id={slot.id}
                                onClick={() => {
                                    addIsSelected(slot);
                                    onSlotSelected(slot);
                                }}
                            />
                            <span>
                                {/* TODO: Determine appointment length */}
                                <span className="u-font-medium u-block">
                                    {slot.date.format("h:mma")} -&nbsp;
                                    {slot.date.add(slot.duration, "minutes").format("h:mma")}
                                </span>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: `${
                                            slot.locationDescription
                                                ? `${slot.locationDescription} <br /> `
                                                : ""
                                        } ${slot.address}`,
                                    }}
                                    className="u-block u-color-navy"
                                ></span>
                            </span>
                        </label>
                    );
                })}
            </div>
            {isNoAppointments && (
                <div className="u-align-center u-flex u-align-items-center u-justify-content-center u-height-100pc">
                    <div>
                        <img src={appointmentPlaceholder} alt="Logo" style={{ width: "185px" }} />
                        <div className="u-width-1 u-color-navy">
                            <p className="u-font-bold u-font-large">No Available Appointments</p>
                            <p>Please try another date</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default Slots;
