import React from "react";

import { observer } from "mobx-react-lite";
import BookingStore, { BookingStep } from "../store/BookingStore";
import Landing from "../components/Landing";
import BookingWizard from "../components/booking-wizard/BookingWizard";

const BookingScreen = observer(() => {
    const store = BookingStore.get();
    switch (store.step) {
        case BookingStep.Landing:
            return <Landing />;
    }

    return <BookingWizard />;
});

export default BookingScreen;
