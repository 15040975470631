import { inject } from "inversify";
import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingService from "../services/BookingService";
import { BOOKING_TYPES } from "../ioc/BookingTypes";
import BookingStore from "booking/store/BookingStore";
import { BookingData } from "common";
/**
 * Attempts to submits all the booking details and the selected appointment date
 */
export default class SubmitBookingUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(bookingData: BookingData) {
        const bookingStore = BookingStore.get();

        try {
            bookingStore.submissionErrorMessage = undefined;
            bookingStore.bookingData = bookingData;
            yield this.service.submitBooking(
                bookingData,
                Number(bookingStore.selectedTimeSlot?.id),
                bookingStore.timeSlotStore.selectedAppointmentTypeId!
            );

            if (bookingStore.appointmentToReschedule) {
                yield this.service.cancelAppointment(bookingStore.appointmentToReschedule, true);
            }
        } catch (error) {
            bookingStore.submissionErrorMessage =
                error?.response?.data?.message || error.message || error;
            bookingStore.returnToSelectAppointment();
            return;
        }

        bookingStore.setAppointmentDetailsConfirmed(true);
    }
}
