import { inject } from "inversify";
import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingStore from "../../store/BookingStore";
import BookingService from "../../services/BookingService";
import { BOOKING_TYPES } from "../../ioc/BookingTypes";
import { AuthenticationType } from "common";

export default class InitiateLoginWithPhoneUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;

    *onExecute(phone: string) {
        const bookingStore = BookingStore.get();

        bookingStore.authenticatingBy = AuthenticationType.Phone;

        try {
            const success = yield this.service.initiateLoginWithPhone(phone);
            if (success) {
                bookingStore.loginErrorMessage = undefined;
                bookingStore.loginCredential = phone;
                bookingStore.disableInitiateLogin();
            } else {
                bookingStore.loginErrorMessage = "Unable to find account";
                bookingStore.loginCredential = undefined;
            }
        } catch (error) {
            bookingStore.loginErrorMessage =
                error?.response?.data?.message || error.message || error;
            bookingStore.loginCredential = undefined;
        }
    }
}
