import "reflect-metadata";
import { configure } from "mobx";
import {
    CORE_TYPES,
    getCoreContainer,
    BrowserLocalStorageService,
    ApiAdapter,
    AxiosApiAdapter,
} from "@gcdtech/acorn-react-core";
import { StyleModule } from "@gcdtech/acorn-react-styleguide";

import BookingModule from "booking/ioc/BookingModule";
import { BOOKING_TYPES } from "booking/ioc/BookingTypes";

// Initialise application
configure({ enforceActions: "observed" });

const container = getCoreContainer();
container.bind(CORE_TYPES.StorageService).to(BrowserLocalStorageService);
container.bind(CORE_TYPES.ApiAdapter).toConstantValue(new AxiosApiAdapter());
container.get<ApiAdapter>(CORE_TYPES.ApiAdapter).init(process.env.REACT_APP_API_URL);
container.bind(BOOKING_TYPES.ApiUri).toConstantValue(process.env.REACT_APP_API_URL);
container.load(new StyleModule());
container.load(new BookingModule());
