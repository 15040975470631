import { inject, injectable } from "inversify";
import { ApiAdapter, CORE_TYPES, AcornUseCase, StorageService } from "@gcdtech/acorn-react-core";
import BookingStore from "../../store/BookingStore";
import { BOOKING_TYPES } from "../../ioc/BookingTypes";
import BookingService from "../../services/BookingService";
import InitialiseBookingUseCase from "../InitialiseBookingUseCase";

@injectable()
export default class LoginWithCodeUseCase extends AcornUseCase {
    @inject(BOOKING_TYPES.BookingService) private service: BookingService;
    @inject(CORE_TYPES.StorageService) private storageService: StorageService;
    @inject(BOOKING_TYPES.TokenStorageKey) private tokenStorageKey: string;
    @inject(CORE_TYPES.ApiAdapter) private apiAdapter: ApiAdapter;

    *onExecute(code: string, onHomeScreen?: boolean) {
        const bookingStore = BookingStore.get();

        try {
            if (!bookingStore.loginCredential) {
                throw new Error("No login credential set");
            }

            const token = yield this.service.loginWithCode(
                code,
                bookingStore.loginCredential,
                bookingStore.authenticatingBy
            );

            bookingStore.configureAuthToken(token);

            if (!onHomeScreen) {
                InitialiseBookingUseCase.create().execute();
                const {
                    marketingEmail,
                    marketingPhone,
                    marketingSMS,
                    marketingPost,
                    aboutUs,
                    ...details
                } = yield this.service.getAuthenticatedUserContactDetails();

                bookingStore.bookingData.contactPreference.email = marketingEmail;
                bookingStore.bookingData.contactPreference.phone = marketingPhone;
                bookingStore.bookingData.contactPreference.sms = marketingSMS;
                bookingStore.bookingData.contactPreference.post = marketingPost;
                bookingStore.bookingData.contactPreference.howDidYouHearAboutUs = aboutUs;

                bookingStore.bookingData.contactDetails = details;

                bookingStore.setEnteringPersonalDetails(true);
            }

            bookingStore.verifyErrorMessage = undefined;
        } catch (error) {
            bookingStore.verifyErrorMessage =
                error?.response?.data?.message || error.message || error;
        }
    }
}
