import BookingStore from "../store/BookingStore";
import InitiateLoginWithEmailUseCase from "../use-cases/authentication/InitiateLoginWithEmailUseCase";
import { ErrorMessage, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Input, Label } from "morse-react";
import React, { useEffect, useState } from "react";
import yup from "core/utils/yup-extended";
import InitiateLoginWithPhoneUseCase from "booking/use-cases/authentication/InitiateLoginWithPhoneUseCase";
import { useOk } from "morse-react-dialogs";
import { useHistory } from "react-router";
import TimeSlotsStore from "../store/TimeSlotStore";
import { appointmentTypes } from "common";

export type LoginInputs = {
    email?: string;
    phone?: string;
};

enum LoginType {
    Email = "Email",
    Phone = "Phone",
}

type LoginProps = {
    onHomeScreen?: boolean;
};

const Login = observer((props: LoginProps) => {
    const history = useHistory();
    const bookingStore = BookingStore.get();
    const timeSlotsStore = TimeSlotsStore.get();

    const { onHomeScreen } = props;

    const [type, setType] = useState<LoginType>(LoginType.Email);

    const errorDialog = useOk({
        title: "Authentication Error",
        message: bookingStore.loginErrorMessage,
    });

    useEffect(() => {
        if (bookingStore.loginErrorMessage) {
            errorDialog.show();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingStore.loginErrorMessage]);

    const store = BookingStore.get();

    const { registeredUserLoginInputs = { email: "", phone: "" } } = store;

    const { email, phone } = registeredUserLoginInputs;

    const onSubmit = async (values: LoginInputs) => {
        switch (type) {
            case LoginType.Email:
                await InitiateLoginWithEmailUseCase.create().execute(values.email || "");
                break;
            case LoginType.Phone:
                await InitiateLoginWithPhoneUseCase.create().execute(values.phone || "");
                break;
        }
    };

    const typeChanged = (e) => {
        if (e.currentTarget.value === "email") {
            setType(e.currentTarget.checked ? LoginType.Email : LoginType.Phone);
        } else {
            setType(e.currentTarget.checked ? LoginType.Phone : LoginType.Email);
        }
    };

    return (
        <Formik
            initialValues={{
                phone: phone,
                email: email,
            }}
            onSubmit={onSubmit}
            validationSchema={
                type === LoginType.Phone
                    ? yup.object({
                          phone: yup
                              .string()
                              .phone("Please enter a valid phone number")
                              .required()
                              .min(3)
                              .max(255),
                      })
                    : yup.object({
                          email: yup
                              .string()
                              .email("Please enter a valid email address")
                              .required()
                              .min(3)
                              .max(255),
                      })
            }
        >
            {({ submitForm, values, handleChange, resetForm, isSubmitting }) => {
                return (
                    <form onSubmit={(e) => e.preventDefault()} className="u-height-100pc u-pad">
                        <div className="u-flex u-align-items-center u-justify-content-center u-height-100pc">
                            <div className="o-wrap u-width-login">
                                <button
                                    type="button"
                                    className="c-button +text +quiet u-pad-none u-translucent"
                                    onClick={() => {
                                        onHomeScreen
                                            ? history.push("/booking")
                                            : store.returnToSelectAppointment();
                                    }}
                                >
                                    <svg className="c-icon u-font-xl u-marg-right-small">
                                        <use href="/symbol-defs.svg#icon-arrow-left" />
                                    </svg>
                                    &nbsp;Back to services
                                </button>
                                <div className="u-border u-rounded u-fill-white u-shadow-ambient">
                                    <div className="u-pad-large">
                                        <p className="u-font-bold u-font-xxl u-marg-bottom">
                                            {onHomeScreen
                                                ? "Login"
                                                : "Login to confirm your appointment"}
                                        </p>

                                        {(email || phone) && (
                                            <p className="u-marg-bottom-large">
                                                Thank you for registering your details. Now please
                                                login so we can verify your credentials.
                                            </p>
                                        )}

                                        {timeSlotsStore.selectedAppointmentTypeId ===
                                            appointmentTypes.stepIntoAction && (
                                            <p className="u-marg-bottom-large">
                                                To book a Step Into Action appointment, you must log
                                                in or create a new account.
                                            </p>
                                        )}

                                        <p className="u-marg-bottom-small">Login using either:</p>
                                        <div className="u-flex u-justify-content-between u-marg-bottom-small">
                                            <div
                                                className="c-checklist +left u-marg-right"
                                                style={{ width: "50%" }}
                                            >
                                                <label
                                                    className="c-radio c-module +stacked u-border-darken u-pad-small!"
                                                    htmlFor="radios_email"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="radios"
                                                        value="email"
                                                        id="radios_email"
                                                        checked={type === LoginType.Email}
                                                        onChange={typeChanged}
                                                        onClick={typeChanged}
                                                    />
                                                    <span className="u-font-medium u-block">
                                                        Email address
                                                    </span>
                                                </label>
                                            </div>
                                            <div
                                                className="c-checklist +left"
                                                style={{ width: "50%" }}
                                            >
                                                <label
                                                    className="c-radio c-module +stacked u-border-darken u-pad-small!"
                                                    htmlFor="radios_phone"
                                                >
                                                    <input
                                                        type="radio"
                                                        id="radios_phone"
                                                        name="radios"
                                                        value="phone"
                                                        checked={type === LoginType.Phone}
                                                        onChange={typeChanged}
                                                        onClick={typeChanged}
                                                    />
                                                    <span className="u-font-medium u-block">
                                                        Phone number
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <fieldset className="u-pad-none">
                                            <Label>
                                                {type === LoginType.Phone
                                                    ? "Phone Number"
                                                    : "Email Address"}
                                            </Label>
                                            <Input
                                                autoFocus
                                                fill
                                                className="+text +fill"
                                                name={type === LoginType.Phone ? "phone" : "email"}
                                                value={
                                                    type === LoginType.Phone
                                                        ? values.phone
                                                        : values.email
                                                }
                                                type={type === LoginType.Phone ? "tel" : "email"}
                                                onChange={handleChange(
                                                    type === LoginType.Phone ? "phone" : "email"
                                                )}
                                            />
                                            <ErrorMessage
                                                component="span"
                                                name={type === LoginType.Phone ? "phone" : "email"}
                                                className="c-field__feedback u-color-link"
                                            />
                                            <div className="u-flex u-marg-top">
                                                <button
                                                    type="submit"
                                                    className="c-button u-fill-1 u-width-100pc u-marg-bottom-small"
                                                    onClick={submitForm}
                                                    disabled={
                                                        isSubmitting || store.initiateLoginDisabled
                                                    }
                                                >
                                                    Login
                                                    <svg className="c-icon u-font-xl u-marg-left-small">
                                                        <use href="/symbol-defs.svg#icon-arrow-right" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <p className="u-align-center u-font-small u-translucent">
                                                We'll verify your details by sending a code to your{" "}
                                                {type === LoginType.Phone ? "phone" : "email"}
                                            </p>
                                        </fieldset>
                                    </div>
                                    <div className="c-divide">
                                        <span>OR</span>
                                    </div>
                                    <div className="u-pad-large">
                                        <div>
                                            <button
                                                type="button"
                                                disabled={
                                                    isSubmitting || store.initiateLoginDisabled
                                                }
                                                className="c-button +quiet u-width-100pc"
                                                onClick={() => {
                                                    store.setEnteringPersonalDetails(true);
                                                    if (onHomeScreen) {
                                                        history.push("/register");
                                                    }
                                                }}
                                            >
                                                <p className="u-font-small">
                                                    Is this your first appointment? Create your
                                                    account here
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="u-pad-large u-hide">
                                        <div>
                                            <button
                                                type="button"
                                                className="c-button +quiet u-width-100pc u-marg-bottom"
                                                onClick={() => {
                                                    setType((prevType) =>
                                                        prevType === LoginType.Phone
                                                            ? LoginType.Email
                                                            : LoginType.Phone
                                                    );
                                                    resetForm();
                                                }}
                                                disabled={isSubmitting}
                                            >
                                                {type === LoginType.Phone
                                                    ? "Login using your email address"
                                                    : "Login using your mobile"}
                                            </button>
                                        </div>
                                        {store.loginErrorMessage && (
                                            <div className="u-border-top u-marg-top">
                                                <div className="c-alert u-marg-left-right u-marg-top u-fill-neg u-bold u-justify-content-start u-align-self-center">
                                                    <span role="img" aria-label="warning">
                                                        ⚠️
                                                    </span>
                                                    <span className="u-flex-grow-1">
                                                        {store.loginErrorMessage}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {!isSubmitting && (
                                                //TODO: Design: style buttons as links
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a
                                                    className="u-block u-align-center u-color-link u-marg-bottom"
                                                    onClick={() => {
                                                        store.setEnteringPersonalDetails(true);
                                                    }}
                                                >
                                                    <u>
                                                        Is this your first appointment? Create your
                                                        account here
                                                    </u>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
});

export default Login;
