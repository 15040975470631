import React, { ComponentProps } from "react";

import { observer } from "mobx-react-lite";
import CIDWizardStep from "./CIDWizardStep";

type CIDWizardChild = React.ReactElement<ComponentProps<typeof CIDWizardStep>>;

type CIDWizardProps = {
    currentStepId: string;
    goToStep: (targetStepId: string) => void;

    // Can be 0, 1 or many children
    children?: CIDWizardChild[] | CIDWizardChild;
};

export const CIDWizard = observer((props: CIDWizardProps) => (
    <>
        {React.Children.map(props.children, (step) => {
            if (step?.props.identifier === props.currentStepId) {
                return step;
            }
        })}
    </>
));

export default CIDWizard;
