import { TimeSlot } from "common";

export const getLocationString = (timeslot: TimeSlot) => {
    let location = timeslot.address ?? "";
    if (timeslot.locationDescription) {
        location = `${timeslot.locationDescription} <br/> ${timeslot.address}`;
    }

    return location;
};
