import React, { ComponentProps } from "react";

import { observer } from "mobx-react-lite";
import CIDWizard from "./CIDWizard";
import logo from "../../../images/logo.png";
import { Countdown } from "../generic/Countdown";
import TimeSlotsStore from "booking/store/TimeSlotStore";
import { appointmentTypeLabels } from "common";
import BookingStore, { BookingWizardStep } from "booking/store/BookingStore";

type CIDTabbedWizardProps = ComponentProps<typeof CIDWizard> & {
    tabContainerClassName?: string;
    stepContainerClassName?: string;
};

export const CIDTabbedWizard = observer((props: CIDTabbedWizardProps) => {
    const { tabContainerClassName, stepContainerClassName, ...wizardProps } = props;

    const timeslotStore = TimeSlotsStore.get();
    const bookingStore = BookingStore.get();

    return (
        <>
            <div className={`${tabContainerClassName}`}>
                <div className="u-border-bottom u-pad-small u-fill-white u-flex u-justify-content-between u-align-items-center">
                    <a href="/">
                        <img src={logo} alt="Logo" style={{ width: "110px" }} className="u-block" />
                    </a>
                    {timeslotStore.selectedAppointmentTypeId && (
                        <p className="u-font-bold u-font-xl u-align-center c-title">
                            Book a {appointmentTypeLabels[timeslotStore.selectedAppointmentTypeId]}{" "}
                            appointment
                        </p>
                    )}
                    <div>
                        {bookingStore.wizardStep !== BookingWizardStep.AppointmentConfirmed &&
                            bookingStore.selectedTimeSlot &&
                            bookingStore.selectedTimeSlot.lockedUntil && (
                                <Countdown
                                    timeLimitSeconds={600}
                                    expiresAt={bookingStore.selectedTimeSlot?.lockedUntil}
                                />
                            )}
                    </div>
                </div>
                <div
                    className={`u-pad-small u-flex u-justify-content-between u-fill-white u-border-bottom`}
                >
                    <nav aria-label="breadcrumbs" className="u-width-100pc">
                        <ol className="c-breadcrumb">
                            {React.Children.map(props.children, (step, index) => {
                                if (!step) {
                                    return null;
                                }
                                const {
                                    heading,
                                    className = "",
                                    identifier,
                                    disabled,
                                } = step.props;
                                return (
                                    step && (
                                        <li
                                            key={`modal-${heading.title}-${index}`}
                                            className={`${className} ${
                                                identifier === props.currentStepId ? "+current" : ""
                                            } ${disabled ? "+disabled" : ""}`}
                                            onClick={() => !disabled && props.goToStep(identifier)}
                                        >
                                            {heading.showStepNumber && (
                                                <span className="c-pill +neg +tighter u-ellipse">
                                                    {index + 1}
                                                </span>
                                            )}
                                            <span>
                                                <span className="c-breadcrumb__count">
                                                    {index + 1}
                                                </span>
                                                &nbsp;
                                                <span className="c-breadcrumb__link-name">
                                                    &nbsp;{heading.title}
                                                </span>
                                            </span>
                                        </li>
                                    )
                                );
                            })}
                        </ol>
                    </nav>
                </div>
            </div>

            <div className={stepContainerClassName}>
                <CIDWizard {...wizardProps} />
            </div>
        </>
    );
});

export default CIDTabbedWizardProps;
