import { AcornUseCase } from "@gcdtech/acorn-react-core";
import BookingStore from "booking/store/BookingStore";
import { Appointment } from "common";
import SelectAppointmentTypeAndEventCodeUseCase from "./SelectAppointmentTypeAndEventCodeUseCase";

/**
 * Sets the appointment to reschedule on the Booking Store.
 *
 * Redirects the user to the available appointments screen so they can choose
 * a new appointment to book.
 *
 * Upon confirmation a check will be made to see if an appointment to reschedule exists
 * and if so the appointment will be cancelled after the confirmation of the new
 * appointment succeeds.
 */
export default class RescheduleAppointmentUseCase extends AcornUseCase {
    *onExecute(appointment: Appointment) {
        const bookingStore = BookingStore.get();
        bookingStore.setAppointmentToReschedule(appointment);

        yield SelectAppointmentTypeAndEventCodeUseCase.create().execute(
            appointment.appointmentTypeId,
            appointment.timeslot.reference ?? ""
        );
    }
}
